import React from 'react'
import { observer      } from 'mobx-react'

import { Page          } from '../layout'
import { PageHeader    } from '../layout'

import { BookList      } from './list'
import { BookDetails   } from './details'

export const Book = observer(() => <Page>
  <PageHeader label="book" />
  <BookList />
  <BookDetails />
</Page>)
