import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createEpisode, { input : toJS(entry) })),
  list   : params => () => {},
  update : ({season,...delta})  => () => API.graphql(graphqlOperation(mutations.updateEpisode, { input : toJS(delta) })),
  delete : entry  => () => {},
})
