import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { Label4    } from '../components'
import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'

import { WordCount } from '../components/wordcount'

const columns = (store) => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.create({
      owner : userStore.user.id,
    }),
  }),
  column.forField('tag',       { width:  50, className: 'centered', headerClassName: 'centered' }),
  column.forField('hidden',    { width: 100, className: 'centered', headerClassName: 'centered', Cell : cell => cell.value ? <i className="fa fa-eye-slash" /> : null }),
  column.forField('schedule',  { width: 100, className: 'centered', headerClassName: 'centered' }),
  column.forField('genre.name', { width: 150 }),
  column.forField('author.name', { width: 150 }),
  column.forField('title'),
  column.forField('size',      { width: 150, headerClassName: 'centered', className: 'right', Cell: cell => <WordCount value={cell.value} label="Episoden" /> }),
  column.forField('wordcount', { width: 150, headerClassName: 'centered', className: 'right', Cell: cell => <WordCount value={cell.value} label="Wörter" /> }),
])

export const SeasonList = inject('seasonStore')(observer(({seasonStore}) => userStore.user.admin ? <>
  <Label4 value="nav.seasons" />
  <DataTable store={seasonStore}
    columns={columns(seasonStore)}
    sorting={[{id : 'tag'},{id : 'title'}]}
  />
</> : null))
