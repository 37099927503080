import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createSeason, { input : toJS(entry) })),
  update : ({author,book,genre,...delta})  => () => API.graphql(graphqlOperation(mutations.updateSeason, { input : toJS(delta) })),
  delete : entry  => () => {},
  patch  : ({author,book,genre,...delta})  =>       API.graphql(graphqlOperation(mutations.updateSeason, { input : toJS(delta) })),
})
