import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries       from '../graphql/queries'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { UpdatingStore } from 'sdc-cms-writing'
import { editingMode   } from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { ui            } from '../design'

import { awsDataToEntry } from 'sdc-data-models'

import api from './api'

const typeID = 'yH639OEyxWBE98MuHAIpHNg3UnUcXqcm'

class BlogStore extends UpdatingStore {

  @observable blogByID = {}

  reloading = null

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'blog',
    })
    this.clearViewing = false
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
      } else {
        this.clearData()
      }
    })

    this.createToEntry = awsDataToEntry('createBlog')
    this.updateToEntry = awsDataToEntry('updateBlog')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
      }
    })

    subscribe('blog-entry-created',  this.blogCreated)
    subscribe('blog-entry-updated',  this.blogUpdated)
    subscribe('blog-entry-selected', this.blogSelected)
  }

  setEditingMode = event => {
    editingMode.setMode('edit')
  }
  setViewingMode = event => {
    editingMode.setMode('view')
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  @action
  clearData = () => {
    this.blogByID = {}
    this.dataList   = []
  }

  parseAWS = deferred => action(data => {
    if (data?.listBlogs?.items) {
      const payload = data.listBlogs.items

      this.blogByID = _.keyBy(payload, 'id')
      this.dataList = _.orderBy(payload, 'name')
    }
    deferred.resolve(this.dataList)
  })

  blogSelected = blog => {
    if (blog) {
      // ui.switchTo(blog.theme)()
      this.setViewingMode()
    }
  }

  blogUpdated = blog => {
    if (blog) {
      this.patch(blog)
      // ui.switchTo(blog.theme)()
    }
  }

  updateTheme = theme => {
    this.patch({
      id : this.selected.id,
      theme,
    })
  }

  setLastUsed = (blog,ts) => action(() => {
    if ((blog.lastUsed || 0) + 10 < ts) {
      this.updateEntryField(this.selected,'lastUsed',ts)
    }
  })

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateBlog(response?.data?.updateBlog)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateBlog = blog => {
    if (blog) {
      this.dataList = update(this.dataList)(blog)
      if (this.selected.id === blog.id) {
        this.selected = blog
        // ui.switchTo(this.selected.theme)()
      }
    }
  }

}

export default ({...options}) => new BlogStore({...options,api:api()})
