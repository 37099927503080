import   React       from 'react'

import { Label          } from 'sdc-i18n-react'
import { languagesStore } from 'sdc-i18n-stores'
import { CreateButton, DeleteButton } from 'sdc-react-basics'

export const createDelete = ({type,onCreate,onDelete,canDelete}) => ({
  Header: onCreate && <CreateButton as="span" onClick={onCreate} title={type+'.create'} />,
  accessor: 'id',
  headerClassName: 'centered',
  className: 'centered',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (typeof canDelete !== 'function' || canDelete(cell)) && onDelete ?
    <DeleteButton as="span" onClick={onDelete(cell.original)} title={type+'.delete'} /> : null,
})

export const forField = (accessor,{ headerClassName = 'left', width, ...props} = {}) => ({
  Header: <Label value={'field.'+accessor} />,
  accessor,
  width,
  headerClassName,
  ...props,
})

export const name  = () => forField('name')
