import React from 'react'
import { observer     } from 'mobx-react'
import { Row, Col     } from 'reactstrap'

import { editingMode  } from 'sdc-cms-writing'

import { shades, hues } from '../../design/ui'
import { ThemeButton  } from './button'

export const ThemeButtons = observer(({store}) => editingMode.isEditMode.get() ? shades.map(shade =>
  <Row className="blog-details" key={shade}>
    <Col md={12}>
      {hues.map(theme => <ThemeButton key={shade+theme} store={store} shade={shade} theme={theme} />)}
    </Col>
  </Row>
) : null)
