import React from 'react'
import { inject, observer } from 'mobx-react'

import { SignIn  } from '../../cognito/signin'
import { SignUp  } from '../../cognito/signup'
import { Pending } from '../../cognito/pending'

export const SignupPanel = observer(() =>
  <div className="col-md-2 side-panel post-suche">
    <SignIn />
    <SignUp />
    <Pending />
  </div>
)
