import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'

import { WordCount } from '../components/wordcount'

const columns = store => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.create({
      owner : userStore.user.id,
    }),
  }),
  column.forField('name', { width: 150 }),
  column.forField('text'),
])

export const BlockList = inject('blockStore')(observer(({blockStore}) =>
<DataTable store={blockStore}
  columns={columns(blockStore)}
/>))
