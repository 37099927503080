import React from 'react'
import { inject, observer  } from 'mobx-react'

import { userStore   } from 'sdc-auth-user'
import { editingMode   } from 'sdc-cms-writing'

import { Trigger       } from '../components'

import { Page          } from '../layout'
import { PageHeader    } from '../layout'

import { ReaderDetails } from './details'

export const Reader = inject('genreStore','seasonStore','readerStore')(observer(({genreStore,seasonStore,readerStore}) => <Page>
  <Trigger topic="reader" />
  <PageHeader label="reader.setting" editable={userStore.user.id} />
  <ReaderDetails />
  {editingMode.isViewMode.get() && readerStore.selected.subscribed && <h4>
    Du erhälst E-Mails aus folgenden Genres und Staffeln:
  </h4>}
  {editingMode.isViewMode.get() && !readerStore.selected.subscribed && <h4>
    Du erhälst keine E-Mails.
  </h4>}
  <p style={{opacity: .5}}>
    Falls du das ändern möchtst, klicke bitte rechts oben auf den Bearbeiten-Button.
  </p>
  {readerStore.selected.subscribed && genreStore.dataList.map(genre => <div key={genre.id}>
    <div>{genre.name}</div>
    {seasonStore.dataList.filter(season => season.genre?.id === genre.id && !season.hidden).map(season => <div key={season.id} style={{marginLeft: '1rem'}}>
      {season.tag} • {season.title}
    </div>)}
  </div>)}
</Page>))
