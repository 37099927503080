import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'


export const BlockDetails = inject('blockStore')(observer(({blockStore}) => {

  if (editingType.typeID !== blockStore.typeID) return null

  const data = {
    store     : blockStore,
    dataStore : blockStore,
    update    : blockStore.update,
  }

  return <Provider {...data}>
    <EntryFieldsList className="blog-details" type="block" fields={['name','text']}
        showLabels={editingMode.isEditMode.get()}
    />
  </Provider>
}))
