import   React              from 'react'
import { inject, observer } from 'mobx-react'

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { userStore } from 'sdc-auth-user'
import { Label     } from 'sdc-i18n-react'

import { SeasonTitle } from './title'

export const SeasonMenu = inject('seasonStore')(observer(({seasonStore,nav}) =>
<UncontrolledDropdown>
  <DropdownToggle caret nav={!!nav} className="blog-menu">
    <SeasonTitle season={seasonStore.selected} />
  </DropdownToggle>
  <DropdownMenu>
    {seasonStore.dataList.filter(k => k.id).map(season =>
      <DropdownItem key={season.id}
          className="blog-menu-entry"
          active={season.id === seasonStore.selected.id}
          onClick={seasonStore.preselect(season)}>
        <div className="clearfix">
          {season.size && <span className="details pull-right">({season.size} <Label value="nav.episodes" />)</span>}
          <SeasonTitle className="blog-name" season={season} />
        </div>
      </DropdownItem>
    )}
  </DropdownMenu>
</UncontrolledDropdown>))
