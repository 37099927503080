import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createBlock, { input : toJS(entry) })),
  list   : params => () => API.graphql({
    query: queries.listBlocks,
    variables: { input: { limit: 1000 } },
    authMode: 'API_KEY',
  }),
  update : delta  => () => API.graphql(graphqlOperation(mutations.updateBlock, { input : toJS(delta) })),
  delete : entry  => () => {},
  patch  : delta  =>       API.graphql(graphqlOperation(mutations.updateBlock, { input : toJS(delta) })),
})
