import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries       from '../graphql/queries'

import { awsDataToEntry } from 'sdc-data-models'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { update        } from 'sdc-mobx-stores'

import { AmplifyStore   } from '../amplify/store'

import { ui            } from '../design'
import { routingStore  } from '../history'

import api from './api'

const typeID = 'YroFn09eDrnHesNtqTYV99yoD9YBIj97'

class GenreStore extends AmplifyStore {

  genreID   = null

  @observable genreByID = {}

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'genreID',
    })
    this.clearViewing = false

    this.createToEntry = awsDataToEntry('createGenre')
    this.updateToEntry = awsDataToEntry('updateGenre')

    subscribe('network-changed', speed => {
      this.reload()
    })

    subscribe('genreID-entry-created',  this.genreCreated)
    subscribe('genreID-entry-updated',  this.genreUpdated)
    subscribe('genreID-entry-selected', this.genreSelected)

    subscribe('genre', id => {
      if (id && id !== this.genreID) {
        console.log('genre changed from',this.genreID,'to',id)
        this.genreID = id
        if (!this.selected.id) {
          setTimeout(() => {
            this.loadByID(id)
          },500)
        }
      }
    })
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  loadByID = id => {
    const variables = {
      filter: {
        id : { eq : id },
      },
    }
    API.graphql({
      query: queries.listGenres,
      variables,
      authMode: 'API_KEY',
    }).then(
      this.parseSelected
    ).catch(error => {
      console.error(error.errors?.[0]?.message || error)
    })
  }

  preselect = genre => e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (genre) {
      routingStore.push('/genre/'+genre.id)
      this.select(genre)(e)
    }
  }

  parseAWS = deferred => action(data => {
    if (data?.listGenres?.items) {
      const payload = data.listGenres.items

      this.genreByID = _.keyBy(payload, 'id')
      this.dataList = _.orderBy(payload, 'name')
    }
    deferred.resolve(this.dataList)
  })

  @action
  parseSelected = response => {
    const payload = response?.data?.listGenres?.items
    if (payload) {
      if (!this.selected.id) {
        this.preselect(payload[0])()
      }
    }
  }

  genreSelected = genre => {
    if (genre) {
      this.setViewingMode()
      ui.switchTo(this.selected.theme)()
    }
  }

  genreUpdated = genre => {
    if (genre) {
      this.patch(genre)
      ui.switchTo(genre.theme)()
    }
  }

  updateTheme = theme => {
    this.patch({
      id : this.selected.id,
      theme,
    })
  }

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateGenre(response?.data?.updateGenre)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateGenre = genre => {
    if (genre) {
      this.dataList = update(this.dataList)(genre)
      if (this.selected.id === genre.id) {
        this.selected = genre
        ui.switchTo(this.selected.theme)()
      }
    }
  }

}

export default ({...options}) => new GenreStore({...options,api:api()})
