import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { EntryFieldsList  } from 'sdc-cms-editing'

import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'

import { mode             } from '../layout/mode'

import { link             } from './md-link'

const viewingFields = mode => mode === 'full' ? ['text'] : ['title','text']
const editingFields = ['title','text','index']

export const PostDetails = inject('postStore')(observer(({postStore}) => {

  if (!postStore.selected.id) return null
  if (editingType.typeID !== postStore.typeID) return null

  const data = {
    store     : postStore,
    dataStore : postStore,
    update    : postStore.update,
  }

  const fieldList = () => editingMode.isViewMode.get() ? viewingFields(mode.state) : editingFields

  return <Provider {...data}>
    <EntryFieldsList className="post-details" type="post" fields={fieldList()}
        showLabels={editingMode.isEditMode.get()}
        onClick={postStore.handleClick}
        options={{
          mdv : {
            link,
          }
        }} />
  </Provider>
}))

