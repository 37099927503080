import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { EntryFieldsList  } from 'sdc-cms-editing'

import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'

import { mode             } from '../layout/mode'

const viewingFields = ['content']
const editingFields = ['key','title','abstract','content','protected','pinned','hidden','published','alpha','beta','draft']

export const EpisodeDetails = inject('episodeStore')(observer(({episodeStore}) => {

  if (!episodeStore.selected.id) return null
  if (editingType.typeID !== episodeStore.typeID) return null

  const data = {
    store     : episodeStore,
    dataStore : episodeStore,
    update    : episodeStore.update,
  }

  const fieldList = () => editingMode.isViewMode.get() ? viewingFields : editingFields

  return <Provider {...data}>
    <EntryFieldsList className="episode-details" type="episode" fields={fieldList()}
        showLabels={editingMode.isEditMode.get()}
        onClick={episodeStore.handleClick}
    />
  </Provider>
}))

