import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'

const columns = (store) => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.create({
      owner : userStore.user.id,
    }),
  }),
  column.name(),
])

export const BlogList = inject('blogStore')(observer(({blogStore}) =>
<DataTable store={blogStore}
  columns={columns(blogStore)}
/>))
