import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'

import { ThemeButtons     } from '../components/theme/buttons'


const viewingFields = ['name','desc']
const editingFields = ['name','genreID','desc','theme']

export const GenreDetails = inject('genreStore')(observer(({genreStore}) => {

    if (editingType.typeID !== genreStore.typeID) return null

    const data = {
    store     : genreStore,
    dataStore : genreStore,
    update    : genreStore.update,
  }

  const fieldList = () => editingMode.isViewMode.get() ? viewingFields : editingFields

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="blog-details" type="genre" fields={fieldList()}
        showLabels={editingMode.isEditMode.get()}
    />
    <ThemeButtons store={genreStore} />
  </React.Fragment></Provider>
}))
