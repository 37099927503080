/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      name
      owner
      size
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        size
        createdAt
        updatedAt
        lastUsed
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      blog
      title
      abstract
      content
      comments
      published
      protected
      pinned
      hidden
      createdAt
      updatedAt
      lastViewed
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blog
        title
        abstract
        content
        comments
        published
        protected
        pinned
        hidden
        createdAt
        updatedAt
        lastViewed
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      owner
      seasonID
      folder
      active
      createdAt
      updatedAt
      lastUsed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        seasonID
        folder
        active
        createdAt
        updatedAt
        lastUsed
        season {
          id
          owner
          genreID
          authorID
          bookID
          title
          tag
          abstract
          intro
          publishing
          published
          hidden
          theme
          schedule
          filepath
          scenelength
          size
          wordcount
          dailytarget
          phase
          alpha
          beta
          createdAt
          updatedAt
          lastUsed
        }
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      postID
      post {
        id
        blog
        title
        abstract
        content
        comments
        published
        protected
        pinned
        hidden
        createdAt
        updatedAt
        lastViewed
      }
      episodeID
      sender
      content
      createdAt
      updatedAt
      episode {
        id
        seasonID
        key
        index
        title
        abstract
        content
        uploaded
        fileID
        fileUUID
        publishing
        publishAt
        published
        alpha
        beta
        draft
        protected
        pinned
        hidden
        comments
        wordcount
        createdAt
        updatedAt
        lastViewed
        season {
          id
          owner
          genreID
          authorID
          bookID
          title
          tag
          abstract
          intro
          publishing
          published
          hidden
          theme
          schedule
          filepath
          scenelength
          size
          wordcount
          dailytarget
          phase
          alpha
          beta
          createdAt
          updatedAt
          lastUsed
        }
        notified
        notifiedAlpha
        notifiedBeta
      }
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        post {
          id
          blog
          title
          abstract
          content
          comments
          published
          protected
          pinned
          hidden
          createdAt
          updatedAt
          lastViewed
        }
        episodeID
        sender
        content
        createdAt
        updatedAt
        episode {
          id
          seasonID
          key
          index
          title
          abstract
          content
          uploaded
          fileID
          fileUUID
          publishing
          publishAt
          published
          alpha
          beta
          draft
          protected
          pinned
          hidden
          comments
          wordcount
          createdAt
          updatedAt
          lastViewed
          notified
          notifiedAlpha
          notifiedBeta
        }
      }
      nextToken
    }
  }
`;
export const getReader = /* GraphQL */ `
  query GetReader($id: ID!) {
    getReader(id: $id) {
      id
      owner
      name
      email
      subscribed
      verified
      admin
      createdAt
      updatedAt
    }
  }
`;
export const listReaders = /* GraphQL */ `
  query ListReaders(
    $id: ID
    $filter: ModelReaderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReaders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        name
        email
        subscribed
        verified
        admin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGenre = /* GraphQL */ `
  query GetGenre($id: ID!) {
    getGenre(id: $id) {
      id
      owner
      genreID
      name
      desc
      theme
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
    }
  }
`;
export const listGenres = /* GraphQL */ `
  query ListGenres(
    $filter: ModelGenreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenres(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      nextToken
    }
  }
`;
export const getSeries = /* GraphQL */ `
  query GetSeries($id: ID!) {
    getSeries(id: $id) {
      id
      owner
      genreID
      authorID
      tag
      title
      abstract
      intro
      theme
      url
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSeriess = /* GraphQL */ `
  query ListSeriess(
    $filter: ModelSeriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeriess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        genreID
        authorID
        tag
        title
        abstract
        intro
        theme
        url
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBook = /* GraphQL */ `
  query GetBook($id: ID!) {
    getBook(id: $id) {
      id
      owner
      genreID
      authorID
      seriesID
      tag
      title
      abstract
      intro
      index
      asin
      theme
      image
      pages
      pinned
      referral
      published
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      series {
        id
        owner
        genreID
        authorID
        tag
        title
        abstract
        intro
        theme
        url
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const listBooks = /* GraphQL */ `
  query ListBooks(
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        genreID
        authorID
        seriesID
        tag
        title
        abstract
        intro
        index
        asin
        theme
        image
        pages
        pinned
        referral
        published
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        series {
          id
          owner
          genreID
          authorID
          tag
          title
          abstract
          intro
          theme
          url
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSeason = /* GraphQL */ `
  query GetSeason($id: ID!) {
    getSeason(id: $id) {
      id
      owner
      genreID
      authorID
      bookID
      title
      tag
      abstract
      intro
      publishing
      published
      hidden
      theme
      schedule
      filepath
      scenelength
      size
      wordcount
      dailytarget
      phase
      alpha
      beta
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      book {
        id
        owner
        genreID
        authorID
        seriesID
        tag
        title
        abstract
        intro
        index
        asin
        theme
        image
        pages
        pinned
        referral
        published
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        series {
          id
          owner
          genreID
          authorID
          tag
          title
          abstract
          intro
          theme
          url
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSeasons = /* GraphQL */ `
  query ListSeasons(
    $filter: ModelSeasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeasons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getEpisode = /* GraphQL */ `
  query GetEpisode($id: ID!) {
    getEpisode(id: $id) {
      id
      seasonID
      key
      index
      title
      abstract
      content
      uploaded
      fileID
      fileUUID
      publishing
      publishAt
      published
      alpha
      beta
      draft
      protected
      pinned
      hidden
      comments
      wordcount
      createdAt
      updatedAt
      lastViewed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      notified
      notifiedAlpha
      notifiedBeta
    }
  }
`;
export const listEpisodes = /* GraphQL */ `
  query ListEpisodes(
    $filter: ModelEpisodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEpisodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        seasonID
        key
        index
        title
        abstract
        content
        uploaded
        fileID
        fileUUID
        publishing
        publishAt
        published
        alpha
        beta
        draft
        protected
        pinned
        hidden
        comments
        wordcount
        createdAt
        updatedAt
        lastViewed
        season {
          id
          owner
          genreID
          authorID
          bookID
          title
          tag
          abstract
          intro
          publishing
          published
          hidden
          theme
          schedule
          filepath
          scenelength
          size
          wordcount
          dailytarget
          phase
          alpha
          beta
          createdAt
          updatedAt
          lastUsed
        }
        notified
        notifiedAlpha
        notifiedBeta
      }
      nextToken
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      owner
      name
      url
      from
      createdAt
      updatedAt
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        url
        from
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAuthor = /* GraphQL */ `
  query GetAuthor($id: ID!) {
    getAuthor(id: $id) {
      id
      owner
      name
      bio
      createdAt
      updatedAt
    }
  }
`;
export const listAuthors = /* GraphQL */ `
  query ListAuthors(
    $filter: ModelAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBlock = /* GraphQL */ `
  query GetBlock($id: ID!) {
    getBlock(id: $id) {
      id
      owner
      name
      text
      createdAt
      updatedAt
    }
  }
`;
export const listBlocks = /* GraphQL */ `
  query ListBlocks(
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
