import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'
import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'

import { Page          } from '../layout'
import { PageHeader    } from '../layout'

import { SeasonList    } from './list'
import { SeasonDetails } from './details'
import { SeasonMenu    } from './right'

export const Season = inject('seasonStore')(observer(({seasonStore}) => <Page left={SeasonMenu}>
  {userStore.user.admin && <SeasonList />}
  <PageHeader label="season">
    {seasonStore.selected.tag}
  </PageHeader>
  <SeasonDetails />
</Page>))
