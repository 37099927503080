import axios  from 'axios'

import { makeTypesStore, makeControlsStore, makeHelpStore, makeIndexStore }  from 'sdc-cms-client'
import { editingMode      } from 'sdc-cms-writing'
import { makeEditingStore } from 'sdc-cms-writing'
import { makeCmsLanguagesStore, makeCmsLabelsStore } from 'sdc-i18n-cms'
import { languagesStore, labelsStore } from 'sdc-i18n-stores'
//import { adSettings, makeAdsStore } from 'sdc-amazon-ads'

import { publish          } from 'sdc-publish-subscribe'

import usersStore           from './data/users/store'

import makeBlogStore from './blog/store'
import makePostStore from './post/store'

import makeGenreStore   from './genre/store'

import makeBookStore    from './book/store'
import makeSeriesStore  from './series/store'

import makeSeasonStore  from './season/store'
import makeEpisodeStore from './episode/store'

import makeCognitoStore from './cognito/store'
import makeReaderStore  from './reader/store'

import makeAuthorStore  from './author/store'
import makeBlockStore   from './block/store'

export { authStore } from 'sdc-auth-store'
export { userStore } from 'sdc-auth-user'

import types     from './data/types'
import languages from './data/languages'
import labels    from './data/labels'

languagesStore.selectLanguage('de')()

editingMode.setMode('view')()

const cmsBase = 'https://www.headless-cms.io/api/'
const spaceID = '4iFOFUaRPicKoNaS6cH3xHfeUZzXF6LL'
const backend = axios.create({
  withCredentials : true,
  baseURL         : cmsBase,
})

const apiURL = 'https://oxfd4slo02.execute-api.eu-central-1.amazonaws.com/dev'
const restAPI = axios.create({
  //withCredentials : true,
  baseURL         : apiURL,
})

let connected = true
const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
if (connection) {
  connection.addEventListener('change', () => {
    console.warn('connection changed to', connection.downlink, connection)
    if (connection.downlink === 0) {
      connected = false
      publish('network-lost')
    } else {
      if (!connected) {
        publish('network-changed', connection.downlink)
      }
      connected = true
    }
  })
}

//const adsStore        = makeAdsStore(       {cmsBase,typeID:'QeJc3lq7Xa0pWXMOqthQnfSBSbsHHmDM'})

const interval = process.env.NODE_ENV === 'production' ? 3600 : 300

export const cmsLanguagesStore = makeCmsLanguagesStore({ backend })
export const cmsLabelsStore    = makeCmsLabelsStore(   { backend, interval })
export const typesStore        = makeTypesStore(       { backend, interval: -1 })
export const editingStore      = makeEditingStore(     { backend, typesStore })
export const controlsStore     = makeControlsStore(    { backend })
export const helpStore         = makeHelpStore(        { backend, labelsStore })

export const blogStore         = makeBlogStore(        {          typesStore, editingStore })
export const postStore         = makePostStore(        {          typesStore, editingStore, blogStore })

export const genreStore        = makeGenreStore(       {          typesStore, editingStore })

export const seriesStore       = makeSeriesStore(      {          typesStore, editingStore })
export const bookStore         = makeBookStore(        {          typesStore, editingStore, seriesStore })

export const seasonStore       = makeSeasonStore(      {          typesStore, editingStore })
export const episodeStore      = makeEpisodeStore(     {          typesStore, editingStore, seasonStore })

export const indexStore        = makeIndexStore(       { backend, typesStore, editingStore, indexProvider : episodeStore })

export const cognitoStore      = makeCognitoStore(     { restAPI })
export const readerStore       = makeReaderStore(      {          typesStore, editingStore })

export const authorStore       = makeAuthorStore(      { backend, typesStore, editingStore })
export const blockStore        = makeBlockStore(       { backend, typesStore, editingStore })

//adSettings.setSize(160)

//typesStore.setSpaceID(spaceID)
typesStore.parseTypes()(types)
cmsLanguagesStore.parseLanguages()(languages)
cmsLabelsStore.parseData()(labels)

export {
//  adsStore,
  usersStore,

  labelsStore,
  languagesStore,

//  adSettings,

//  booksStore,

//  postsStore,
//  categoriesCloud,
//  categoriesStore,
//  genresCloud,
//  genresStore,
}
