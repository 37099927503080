import React from 'react'
import cn from 'classnames'
import moment from 'moment'

import { userStore     } from 'sdc-auth-user'

import { EpisodeTitle } from './title'

export const episodeToListEntry = (episodeStore,noIndex,extended,noTag) => (episode) =>
<div key={episode.id || '?'}
    className={cn('search-result', episode.season?.theme || 'darkCyan', episode.hidden && 'hidden', episode.id === episodeStore.selected.id && 'selected')}
    onClick={episodeStore.preselect(episode)}>
  {!noTag && <span className="episode-index" style={{opacity: 0.5}}> {episode.season?.tag} {userStore.user.admin && <span> {episode.key} </span>} </span>}
  {!noIndex && <span className="episode-index"> {episode.index} • </span>}
  <EpisodeTitle episode={episode} />
  {extended && <div style={{fontSize: '85%'}} className="clearfix">
    {episode.key} •
    {episode.wordcount > 0 && <span style={{opacity: 0.5}}> {episode.wordcount} Wörter </span>}
    {episode.published && <span className="pull-right" style={{opacity: episode.notified > 0 && moment(episode.published).isBefore(moment()) ? 0.2 : 0.5}}>
      {moment(episode.published).format(' dd DD.MM.YY HH:mm ')}
      {moment(episode.published).isBefore(moment()) && <i className="fa fa-envelope-open" style={{color: '#0c0'}} />}
      {moment(episode.published).isAfter(moment())  && <i className="fa fa-envelope"      style={{color: '#f33'}} />}
      {episode.notified > 0 && <span onClick={episodeStore.resetNotified(episode)}> {episode.notified} </span>}
      {moment(episode.published).isBefore(moment()) && episode.notified === 0 && <i className="fa fa-hourglass" style={{color: '#f96', marginLeft: '4px'}} />}
    </span>}
  </div>}
</div>
