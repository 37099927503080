import React from 'react'
import { inject, observer } from 'mobx-react'

import { postToPlainDraggable } from '../../post/helpers'

import { mode       } from '../mode'
import { BookCard   } from '../../book/card'

export const BookPanel = inject('bookStore')(observer(({bookStore}) =>
  <div className="col-md-2 side-panel left-side post-suche custom-scrollbar">
    {bookStore.featured.id && <BookCard book={bookStore.featured} />}
  </div>
))
