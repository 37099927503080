/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBlog = /* GraphQL */ `
  subscription OnCreateBlog {
    onCreateBlog {
      id
      name
      owner
      size
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onUpdateBlog = /* GraphQL */ `
  subscription OnUpdateBlog {
    onUpdateBlog {
      id
      name
      owner
      size
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onDeleteBlog = /* GraphQL */ `
  subscription OnDeleteBlog {
    onDeleteBlog {
      id
      name
      owner
      size
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      blog
      title
      abstract
      content
      comments
      published
      protected
      pinned
      hidden
      createdAt
      updatedAt
      lastViewed
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      blog
      title
      abstract
      content
      comments
      published
      protected
      pinned
      hidden
      createdAt
      updatedAt
      lastViewed
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      blog
      title
      abstract
      content
      comments
      published
      protected
      pinned
      hidden
      createdAt
      updatedAt
      lastViewed
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject {
    onCreateProject {
      id
      owner
      seasonID
      folder
      active
      createdAt
      updatedAt
      lastUsed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject {
    onUpdateProject {
      id
      owner
      seasonID
      folder
      active
      createdAt
      updatedAt
      lastUsed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject {
    onDeleteProject {
      id
      owner
      seasonID
      folder
      active
      createdAt
      updatedAt
      lastUsed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      postID
      post {
        id
        blog
        title
        abstract
        content
        comments
        published
        protected
        pinned
        hidden
        createdAt
        updatedAt
        lastViewed
      }
      episodeID
      sender
      content
      createdAt
      updatedAt
      episode {
        id
        seasonID
        key
        index
        title
        abstract
        content
        uploaded
        fileID
        fileUUID
        publishing
        publishAt
        published
        alpha
        beta
        draft
        protected
        pinned
        hidden
        comments
        wordcount
        createdAt
        updatedAt
        lastViewed
        season {
          id
          owner
          genreID
          authorID
          bookID
          title
          tag
          abstract
          intro
          publishing
          published
          hidden
          theme
          schedule
          filepath
          scenelength
          size
          wordcount
          dailytarget
          phase
          alpha
          beta
          createdAt
          updatedAt
          lastUsed
        }
        notified
        notifiedAlpha
        notifiedBeta
      }
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      postID
      post {
        id
        blog
        title
        abstract
        content
        comments
        published
        protected
        pinned
        hidden
        createdAt
        updatedAt
        lastViewed
      }
      episodeID
      sender
      content
      createdAt
      updatedAt
      episode {
        id
        seasonID
        key
        index
        title
        abstract
        content
        uploaded
        fileID
        fileUUID
        publishing
        publishAt
        published
        alpha
        beta
        draft
        protected
        pinned
        hidden
        comments
        wordcount
        createdAt
        updatedAt
        lastViewed
        season {
          id
          owner
          genreID
          authorID
          bookID
          title
          tag
          abstract
          intro
          publishing
          published
          hidden
          theme
          schedule
          filepath
          scenelength
          size
          wordcount
          dailytarget
          phase
          alpha
          beta
          createdAt
          updatedAt
          lastUsed
        }
        notified
        notifiedAlpha
        notifiedBeta
      }
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      postID
      post {
        id
        blog
        title
        abstract
        content
        comments
        published
        protected
        pinned
        hidden
        createdAt
        updatedAt
        lastViewed
      }
      episodeID
      sender
      content
      createdAt
      updatedAt
      episode {
        id
        seasonID
        key
        index
        title
        abstract
        content
        uploaded
        fileID
        fileUUID
        publishing
        publishAt
        published
        alpha
        beta
        draft
        protected
        pinned
        hidden
        comments
        wordcount
        createdAt
        updatedAt
        lastViewed
        season {
          id
          owner
          genreID
          authorID
          bookID
          title
          tag
          abstract
          intro
          publishing
          published
          hidden
          theme
          schedule
          filepath
          scenelength
          size
          wordcount
          dailytarget
          phase
          alpha
          beta
          createdAt
          updatedAt
          lastUsed
        }
        notified
        notifiedAlpha
        notifiedBeta
      }
    }
  }
`;
export const onCreateReader = /* GraphQL */ `
  subscription OnCreateReader($owner: String) {
    onCreateReader(owner: $owner) {
      id
      owner
      name
      email
      subscribed
      verified
      admin
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReader = /* GraphQL */ `
  subscription OnUpdateReader($owner: String) {
    onUpdateReader(owner: $owner) {
      id
      owner
      name
      email
      subscribed
      verified
      admin
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReader = /* GraphQL */ `
  subscription OnDeleteReader($owner: String) {
    onDeleteReader(owner: $owner) {
      id
      owner
      name
      email
      subscribed
      verified
      admin
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGenre = /* GraphQL */ `
  subscription OnCreateGenre($owner: String) {
    onCreateGenre(owner: $owner) {
      id
      owner
      genreID
      name
      desc
      theme
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
    }
  }
`;
export const onUpdateGenre = /* GraphQL */ `
  subscription OnUpdateGenre($owner: String) {
    onUpdateGenre(owner: $owner) {
      id
      owner
      genreID
      name
      desc
      theme
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
    }
  }
`;
export const onDeleteGenre = /* GraphQL */ `
  subscription OnDeleteGenre($owner: String) {
    onDeleteGenre(owner: $owner) {
      id
      owner
      genreID
      name
      desc
      theme
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
    }
  }
`;
export const onCreateSeries = /* GraphQL */ `
  subscription OnCreateSeries($owner: String) {
    onCreateSeries(owner: $owner) {
      id
      owner
      genreID
      authorID
      tag
      title
      abstract
      intro
      theme
      url
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateSeries = /* GraphQL */ `
  subscription OnUpdateSeries($owner: String) {
    onUpdateSeries(owner: $owner) {
      id
      owner
      genreID
      authorID
      tag
      title
      abstract
      intro
      theme
      url
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteSeries = /* GraphQL */ `
  subscription OnDeleteSeries($owner: String) {
    onDeleteSeries(owner: $owner) {
      id
      owner
      genreID
      authorID
      tag
      title
      abstract
      intro
      theme
      url
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateBook = /* GraphQL */ `
  subscription OnCreateBook($owner: String) {
    onCreateBook(owner: $owner) {
      id
      owner
      genreID
      authorID
      seriesID
      tag
      title
      abstract
      intro
      index
      asin
      theme
      image
      pages
      pinned
      referral
      published
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      series {
        id
        owner
        genreID
        authorID
        tag
        title
        abstract
        intro
        theme
        url
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateBook = /* GraphQL */ `
  subscription OnUpdateBook($owner: String) {
    onUpdateBook(owner: $owner) {
      id
      owner
      genreID
      authorID
      seriesID
      tag
      title
      abstract
      intro
      index
      asin
      theme
      image
      pages
      pinned
      referral
      published
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      series {
        id
        owner
        genreID
        authorID
        tag
        title
        abstract
        intro
        theme
        url
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteBook = /* GraphQL */ `
  subscription OnDeleteBook($owner: String) {
    onDeleteBook(owner: $owner) {
      id
      owner
      genreID
      authorID
      seriesID
      tag
      title
      abstract
      intro
      index
      asin
      theme
      image
      pages
      pinned
      referral
      published
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      series {
        id
        owner
        genreID
        authorID
        tag
        title
        abstract
        intro
        theme
        url
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateSeason = /* GraphQL */ `
  subscription OnCreateSeason {
    onCreateSeason {
      id
      owner
      genreID
      authorID
      bookID
      title
      tag
      abstract
      intro
      publishing
      published
      hidden
      theme
      schedule
      filepath
      scenelength
      size
      wordcount
      dailytarget
      phase
      alpha
      beta
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      book {
        id
        owner
        genreID
        authorID
        seriesID
        tag
        title
        abstract
        intro
        index
        asin
        theme
        image
        pages
        pinned
        referral
        published
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        series {
          id
          owner
          genreID
          authorID
          tag
          title
          abstract
          intro
          theme
          url
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateSeason = /* GraphQL */ `
  subscription OnUpdateSeason {
    onUpdateSeason {
      id
      owner
      genreID
      authorID
      bookID
      title
      tag
      abstract
      intro
      publishing
      published
      hidden
      theme
      schedule
      filepath
      scenelength
      size
      wordcount
      dailytarget
      phase
      alpha
      beta
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      book {
        id
        owner
        genreID
        authorID
        seriesID
        tag
        title
        abstract
        intro
        index
        asin
        theme
        image
        pages
        pinned
        referral
        published
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        series {
          id
          owner
          genreID
          authorID
          tag
          title
          abstract
          intro
          theme
          url
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteSeason = /* GraphQL */ `
  subscription OnDeleteSeason {
    onDeleteSeason {
      id
      owner
      genreID
      authorID
      bookID
      title
      tag
      abstract
      intro
      publishing
      published
      hidden
      theme
      schedule
      filepath
      scenelength
      size
      wordcount
      dailytarget
      phase
      alpha
      beta
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      book {
        id
        owner
        genreID
        authorID
        seriesID
        tag
        title
        abstract
        intro
        index
        asin
        theme
        image
        pages
        pinned
        referral
        published
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        series {
          id
          owner
          genreID
          authorID
          tag
          title
          abstract
          intro
          theme
          url
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateEpisode = /* GraphQL */ `
  subscription OnCreateEpisode {
    onCreateEpisode {
      id
      seasonID
      key
      index
      title
      abstract
      content
      uploaded
      fileID
      fileUUID
      publishing
      publishAt
      published
      alpha
      beta
      draft
      protected
      pinned
      hidden
      comments
      wordcount
      createdAt
      updatedAt
      lastViewed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      notified
      notifiedAlpha
      notifiedBeta
    }
  }
`;
export const onUpdateEpisode = /* GraphQL */ `
  subscription OnUpdateEpisode {
    onUpdateEpisode {
      id
      seasonID
      key
      index
      title
      abstract
      content
      uploaded
      fileID
      fileUUID
      publishing
      publishAt
      published
      alpha
      beta
      draft
      protected
      pinned
      hidden
      comments
      wordcount
      createdAt
      updatedAt
      lastViewed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      notified
      notifiedAlpha
      notifiedBeta
    }
  }
`;
export const onDeleteEpisode = /* GraphQL */ `
  subscription OnDeleteEpisode {
    onDeleteEpisode {
      id
      seasonID
      key
      index
      title
      abstract
      content
      uploaded
      fileID
      fileUUID
      publishing
      publishAt
      published
      alpha
      beta
      draft
      protected
      pinned
      hidden
      comments
      wordcount
      createdAt
      updatedAt
      lastViewed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      notified
      notifiedAlpha
      notifiedBeta
    }
  }
`;
export const onCreateSite = /* GraphQL */ `
  subscription OnCreateSite($owner: String) {
    onCreateSite(owner: $owner) {
      id
      owner
      name
      url
      from
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSite = /* GraphQL */ `
  subscription OnUpdateSite($owner: String) {
    onUpdateSite(owner: $owner) {
      id
      owner
      name
      url
      from
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSite = /* GraphQL */ `
  subscription OnDeleteSite($owner: String) {
    onDeleteSite(owner: $owner) {
      id
      owner
      name
      url
      from
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAuthor = /* GraphQL */ `
  subscription OnCreateAuthor($owner: String) {
    onCreateAuthor(owner: $owner) {
      id
      owner
      name
      bio
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAuthor = /* GraphQL */ `
  subscription OnUpdateAuthor($owner: String) {
    onUpdateAuthor(owner: $owner) {
      id
      owner
      name
      bio
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAuthor = /* GraphQL */ `
  subscription OnDeleteAuthor($owner: String) {
    onDeleteAuthor(owner: $owner) {
      id
      owner
      name
      bio
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBlock = /* GraphQL */ `
  subscription OnCreateBlock($owner: String) {
    onCreateBlock(owner: $owner) {
      id
      owner
      name
      text
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBlock = /* GraphQL */ `
  subscription OnUpdateBlock($owner: String) {
    onUpdateBlock(owner: $owner) {
      id
      owner
      name
      text
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBlock = /* GraphQL */ `
  subscription OnDeleteBlock($owner: String) {
    onDeleteBlock(owner: $owner) {
      id
      owner
      name
      text
      createdAt
      updatedAt
    }
  }
`;
