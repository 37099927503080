import React from 'react'
import { inject, observer } from 'mobx-react'

import moment from 'moment'

import { labelsStore } from 'sdc-i18n-stores'
import { userStore   } from 'sdc-auth-user'

export const EpisodeTitle = inject('seasonStore')(observer(({seasonStore,episode,noPin,showSeason,showPublished,showGenre,...props}) =>
<span {...props}>
  {showSeason && episode.season?.tag && <span style={{opacity: 0.5}}> {episode.season?.tag} <span style={{opacity: 0.5}}> • </span> </span>}
  {episode?.id && userStore.user.admin && episode.draft && <i className={'pull-left fa fa-fw fa-warning'} style={{marginTop: '4px', color: '#f63'}} />}
  {episode?.id && !noPin && (!episode.draft || !userStore.user.admin) && <i className={'pull-left  fa fa-fw fa-thumb-tack pinned-'+episode?.pinned} style={{marginTop: '4px'}} />}
  {episode?.id && !noPin && episode.protected &&  userStore.user.id && !userStore.user.admin && <i className={'pull-right fa fa-fw fa-unlock'} style={{marginTop: '4px', color: '#3f3', opacity: .5}} />}
  {episode?.id && !noPin && episode.protected && (!userStore.user.id || userStore.user.admin) && <i className={'pull-right fa fa-fw fa-lock'} style={{marginTop: '4px', color: '#c96'}} />}
  {episode?.id && showPublished && <span className={'pull-right'} style={{color: '#999', marginLeft: '8px'}}>{moment(episode.published).format('DD. MMMM')}</span>}
  {episode?.id && showGenre     && <span className={'pull-right'} style={{color: '#999', marginLeft: '8px'}}>{seasonStore.seasonByID[episode.season?.id]?.genre?.name}</span>}
  {episode?.title || labelsStore.labelFor('episode.unnamed')}
</span>))
