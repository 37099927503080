import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries       from '../graphql/queries'

import { awsDataToEntry } from 'sdc-data-models'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { update        } from 'sdc-mobx-stores'

import { AmplifyStore   } from '../amplify/store'

import { ui            } from '../design'
import { routingStore  } from '../history'

import api from './api'

const typeID = 'W9q4Q0FKP2WuxiBESZgZCcMgH5H6QNQO'

class SeasonStore extends AmplifyStore {

  seasonID   = null

  @observable seasonByID = {}

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'season',
    })
    this.clearViewing = false

    autorun(() => {
      if (userStore.user.admin) {
        setTimeout(this.reload, 500)
      }
    })

    this.createToEntry = awsDataToEntry('createSeason')
    this.updateToEntry = awsDataToEntry('updateSeason')

    subscribe('network-changed', speed => {
      this.reload()
    })

    subscribe('season-entry-created',  this.seasonCreated)
    subscribe('season-entry-updated',  this.seasonUpdated)
    subscribe('season-entry-selected', this.seasonSelected)

    subscribe('season', id => {
      if (id && id !== this.seasonID) {
        console.log('season changed from',this.seasonID,'to',id)
        this.seasonID = id
        setTimeout(() => {
          this.loadByID(id)
        },100)
      }
    })
  }

  reload = () => {
    const variables = userStore.user.admin ? undefined : {
      filter: {
        or: [
          { hidden : { attributeExists: false} },
          { hidden : { eq: false } },
        ],
      },
    }
    API.graphql({
      query: queries.listSeasons,
      variables,
      authMode: 'API_KEY',
    }).then(
      this.parseAWS
    ).catch(error => {
      console.error(error.errors?.[0]?.message || error)
    })
  }

  loadByID = id => {
    const variables = {
      filter: {
        id : { eq : id },
      },
    }
    API.graphql({
      query: queries.listSeasons,
      variables,
      authMode: 'API_KEY',
    }).then(
      this.parseSelected
    ).catch(error => {
      console.error(error.errors?.[0]?.message || error)
    })
  }

  preselect = season => e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (season) {
      routingStore.push('/season/'+season.id)
      this.select(season)(e)
    }
  }

  @action
  parseAWS = response => {
    const payload = response?.data?.listSeasons?.items
    if (payload) {
      this.seasonByID = _.keyBy(payload, 'id')
      this.dataList = _.orderBy(payload, 'tag')
    }
  }

  @action
  parseSelected = response => {
    const payload = response?.data?.listSeasons?.items
    if (payload) {
      this.preselect(payload[0])()
    }
  }

  seasonSelected = season => {
    if (season) {
      this.setViewingMode()
      ui.switchTo(this.selected.theme)()
    }
  }

  seasonUpdated = season => {
    if (season) {
      this.patch(season)
      ui.switchTo(season.theme)()
    }
  }

  updateTheme = theme => {
    this.patch({
      id : this.selected.id,
      theme,
    })
  }

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateSeason({
        ...response?.data?.updateSeason,
        ...delta,
      })
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateSeason = season => {
    if (season) {
      this.dataList = update(this.dataList)(season)
      if (this.selected.id === season.id) {
        this.selected = season
        ui.switchTo(this.selected.theme)()
      }
    }
  }

}

export default ({...options}) => new SeasonStore({...options,api:api()})
