import { action, observable, extendObservable, toJS } from 'mobx'

import moment from 'moment'

import jwt       from 'jsonwebtoken'
import jwtDecode from 'jwt-decode'

import { toast } from 'react-toastify'

import { Auth } from 'aws-amplify'

import { userStore } from 'sdc-auth-user'
import { repeater  } from 'sdc-utilities'

import { history   } from '../history'

import api from './api'

const intToHex = n => n.toString(16).padStart(2, '0')

const randomString = (bytes = 30) => {
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)
  return Array.from(randomValues).map(intToHex).join('')
}

class CognitoStore {

  @observable name  = window.localStorage.getItem('magic-books-name')  || ''
  @observable email = window.localStorage.getItem('magic-books-email') || ''
  @observable known = !!window.localStorage.getItem('magic-books-known')
  @observable pending = false

  constructor({
    backend
  }) {
    this.backend = backend

    repeater({
      action   : this.refreshToken,
      interval : 600,
      jitter   : 0,
    })
  }

  @action
  updatePending = () => {
    this.pending = moment(window.localStorage.getItem('magic-books-sent')).isAfter(moment(),'minute')
  }

  @action
  updateKnown = known => {
    this.known = known
  }

  @action
  updateName = e => {
    this.name = e.target.value
    window.localStorage.setItem('magic-books-name', this.name)
  }

  @action
  updateEmail = e => {
    this.email = e.target.value
    window.localStorage.setItem('magic-books-email', this.email)
    window.localStorage.removeItem('magic-books-known')
    this.updateKnown(false)
  }

  refreshToken = async () => {

    this.updatePending()

    try {
      const session = await Auth.currentSession()
      const token = session.idToken.jwtToken
      const data = jwtDecode(token)
      const groups = data['cognito:groups']
      if (data.sub) {
        userStore.setUser({
          id    : data.sub,
          name  : data.name,
          email : data.email,
          groups,
          admin : groups.includes('magbooksAdmins'),
        })
        window.localStorage.setItem('magic-books-known', true)
        window.localStorage.removeItem('magic-books-sent')
        this.updatePending()
        this.updateKnown(true)
        setTimeout(() => {
          if (this.toastId) {
            toast.update(this.toastId, {
              render: 'Du bist wieder angemeldet.',
              autoClose: 1000,
              type: 'success',
            })
          }
        }, 100)
      } else {
        userStore.setUser({})
      }
    } catch(e) {
      console.log(e)
      userStore.setUser({})
    }
  }

  signUp = async e => {

    const params = {
      username: this.email,
      password: randomString(),
      attributes: {
        name: this.name,
        'custom:host': window.location.origin,
      },
    }

    let cognitoUser = {}
    const toastId = toast.info('E-Mail für Registrierung wird vorbereitet...')
    try {
      cognitoUser = await Auth.signUp(params)
      toast.update(toastId, {
        render: 'Du hast eine E-Mail mit einem Link zur Bestätigung deiner Registrierung erhalten.'
      })
    } catch(e) {
      console.error(e)
      toast.update(toastId, {
        autoClose: 3000,
        render: 'E-Mail für Anmeldung wird vorbereitet...'
      })
      const update = await this.backend.update({
        UserAttributes: [{
          Name: 'custom:host',
          Value: window.location.origin
        }],
        UserPoolId: 'eu-central-1_epbabFNGd',
        Username  : this.email,
      })
      cognitoUser = await Auth.signIn(this.email)
      toast.update(toastId, {
        autoClose: 3000,
        render: 'Du hast eine E-Mail mit einem Link zur sicheren Anmeldung erhalten.'
      })
      window.localStorage.setItem('magic-books-sent', moment().add(30,'minutes').toISOString())
      this.updatePending()
    }
    console.log(cognitoUser)
    window.localStorage.setItem('magic-books-session', cognitoUser.Session)
    window.localStorage.setItem('magic-books-known', true)
    this.updateKnown(true)
  }

  signIn = async data => {
    const session = window.localStorage.getItem('magic-books-session')
    if (!session) {
      console.log('no session')
      if (!userStore.user.id) {
        history.push('/')
        this.toastId = toast.error('Es wurde keine passende Session gefunden. Bitte starte die Anmeldung erneut.')
      }
      return
    }
    try {
      const toastId = toast.info('Du wirst angemeldet...')
      const payload = jwt.verify(data.signin,'shadow')
      window.localStorage.removeItem('magic-books-sent')
      this.updatePending()
      console.log('signin payload:', payload)
      const cognitoUser = Auth.createCognitoUser(payload.sub)
      cognitoUser.authenticationFlowType = 'CUSTOM_AUTH'
      cognitoUser.challengeName = 'CUSTOM_CHALLENGE'
      cognitoUser.Session = session
      await Auth.sendCustomChallengeAnswer(cognitoUser,data.code)
      this.refreshToken()
      window.localStorage.removeItem('magic-books-session')
      history.push('/')
      toast.update(toastId,{
        render: 'Du bist jetzt angemeldet.',
        type: 'success'
      })
    } catch(e) {
      console.error(e)
    }
  }

  signOut = e => {
    Auth.signOut()
    userStore.setUser({})
    history.push('/')
    toast.warning('Du bist jetzt abgemeldet.')
  }

}

export default ({restAPI,...options}) => new CognitoStore({...options,backend:api(restAPI)})
