import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'

const columns = (store) => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.create({
      //owner : userStore.user.id,
    }),
  }),
  column.forField('series.title'),
  column.forField('tag',   { width: 100, className: 'centered', headerClassName: 'centered' }),
  column.forField('index', { width:  60, className: 'centered', headerClassName: 'centered' }),
  column.forField('pinned',{ width: 100, className: 'centered', Cell : cell => cell.value ? <span>✓</span> : null }),
  column.forField('author.name', { width: 150 }),
  column.forField('title'),
  column.forField('asin' , { width: 150 }),
  column.forField('image', { width: 150 }),
])

export const BookList = inject('bookStore')(observer(({bookStore}) =>
<DataTable store={bookStore}
  columns={columns(bookStore)}
  sorting={[{id : 'tag'},{id : 'index'},{id : 'title'}]}
/>))
