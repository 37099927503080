import   React              from 'react'
import { inject, observer } from 'mobx-react'

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { userStore } from 'sdc-auth-user'
import { Label     } from 'sdc-i18n-react'

import { BlogName } from './name'

export const BlogMenu = inject('blogStore')(observer(({blogStore,nav}) => userStore.user.id ?
<UncontrolledDropdown>
  <DropdownToggle caret nav={!!nav} className="blog-menu">
    <BlogName blog={blogStore.selected} />
  </DropdownToggle>
  <DropdownMenu>
    {blogStore.dataList.filter(k => k.id).map(blog =>
      <DropdownItem key={blog.id}
          className="blog-menu-entry"
          active={blog.id === blogStore.selected.id}
          onClick={blogStore.select(blog)}>
        <div className="clearfix">
          {blog.size && <span className="details pull-right">({blog.size} <Label value="nav.posts" />)</span>}
          <BlogName className="blog-name" blog={blog} />
        </div>
      </DropdownItem>
    )}
  </DropdownMenu>
</UncontrolledDropdown> : null))
