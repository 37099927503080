import React from 'react'
import { observer      } from 'mobx-react'

import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'

import { Page          } from '../layout'
import { PageHeader    } from '../layout'

import { SeriesList    } from './list'
import { SeriesDetails } from './details'

export const Series = observer(() => <Page>
  <PageHeader label="series" />
  <SeriesList />
  <SeriesDetails />
</Page>)
