import React from 'react'
import { useParams } from 'react-router-dom'

import { publish   } from 'sdc-publish-subscribe'

export const Trigger = ({topic}) => {

  const { id } = useParams()
  publish(topic,id)

  return null
}
