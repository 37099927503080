import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries       from '../graphql/queries'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { update        } from 'sdc-mobx-stores'

import { AmplifyStore   } from '../amplify/store'

import { ui            } from '../design'

import { awsDataToEntry } from 'sdc-data-models'

import api from './api'

const typeID = 'ejAdldh5awx24yxKcTbPpkoNXYmAerSf'

class BookStore extends AmplifyStore {

  @observable bookByID = {}

  @observable featured = {}

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'bookID',
    })
    this.clearViewing = false

    this.createToEntry = awsDataToEntry('createBook')
    this.updateToEntry = awsDataToEntry('updateBook')

    subscribe('network-changed', speed => {
      this.reload()
    })

    subscribe('bookID-entry-created',  this.bookCreated)
    subscribe('bookID-entry-updated',  this.bookUpdated)
    subscribe('bookID-entry-selected', this.bookSelected)
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  preselect = book => e => {
    e.preventDefault()
    e.stopPropagation()
    this.select(book)(e)
  }

  parseAWS = deferred => action(data => {
    if (data?.listBooks?.items) {
      const payload = data.listBooks.items

      this.bookByID = _.keyBy(payload, 'id')
      this.dataList = _.orderBy(payload, 'index')

      this.updateFeatured()
    }
    deferred.resolve(this.dataList)
  })

  @action
  updateFeatured = () => {
    const featured = _.shuffle(this.dataList.filter(b => b.asin))
    if (featured.length) {
      this.featured = _.sortBy(featured, b => !b.pinned)[0]
    }
  }

  @action
  bookSelected = book => {
    if (book) {
      this.setViewingMode()
      ui.switchTo(this.selected.theme)()
      this.featured = book
    }
  }

  bookUpdated = book => {
    if (book) {
      this.patch(book)
      ui.switchTo(book.theme)()
    }
  }

  updateTheme = theme => {
    this.patch({
      id : this.selected.id,
      theme,
    })
  }

  setLastUsed = (book,ts) => action(() => {
    if ((book.lastUsed || 0) + 10 < ts) {
      this.updateEntryField(this.selected,'lastUsed',ts)
    }
  })

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateBook(response?.data?.updateBook)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateBook = book => {
    if (book) {
      this.dataList = update(this.dataList)(book)
      if (this.selected.id === book.id) {
        this.selected = book
        ui.switchTo(this.selected.theme)()
      }
    }
  }

}

export default ({...options}) => new BookStore({...options,api:api()})
