import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries       from '../graphql/queries'

import { awsDataToEntry } from 'sdc-data-models'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { update        } from 'sdc-mobx-stores'

import { AmplifyStore   } from '../amplify/store'

import { routingStore  } from '../history'

import api from './api'

const typeID = 'wDaeakkEDGqZOQ2GzLLOrqvt30sAr8nT'

class AuthorStore extends AmplifyStore {

  authorID   = null

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'authorID',
    })
    this.clearViewing = false

    this.createToEntry = awsDataToEntry('createAuthor')
    this.updateToEntry = awsDataToEntry('updateAuthor')

    subscribe('network-changed', speed => {
      this.reload()
    })

    subscribe('authorID-entry-created',  this.authorCreated)
    subscribe('authorID-entry-updated',  this.authorUpdated)
    subscribe('authorID-entry-selected', this.authorSelected)

    subscribe('author', id => {
      if (id && id !== this.authorID) {
        console.log('author changed from',this.authorID,'to',id)
        this.authorID = id
        if (!this.selected.id) {
          setTimeout(() => {
            this.loadByID(id)
          },500)
        }
      }
    })
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  loadByID = id => {
    const variables = {
      filter: {
        id : { eq : id },
      },
      limit: 1000
    }
    API.graphql({
      query: queries.listAuthors,
      variables,
      authMode: 'API_KEY',
    }).then(
      this.parseSelected
    ).catch(error => {
      console.error(error.errors?.[0]?.message || error)
    })
  }

  preselect = author => e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (author) {
      routingStore.push('/author/'+author.id)
      this.select(author)(e)
    }
  }

  parseAWS = deferred => action(data => {
    if (data?.listAuthors?.items) {
      const payload = data.listAuthors.items

      this.dataList = _.orderBy(payload, 'name')
    }
    deferred.resolve(this.dataList)
  })

  @action
  parseSelected = response => {
    const payload = response?.data?.listAuthors?.items
    if (payload) {
      if (!this.selected.id) {
        this.preselect(payload[0])()
      }
    }
  }

  authorSelected = author => {
    if (author) {
      this.setViewingMode()
    }
  }

  authorUpdated = author => {
    if (author) {
      this.patch(author)
    }
  }

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateAuthor(response?.data?.updateAuthor)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateAuthor = author => {
    if (author) {
      this.dataList = update(this.dataList)(author)
      if (this.selected.id === author.id) {
        this.selected = author
      }
    }
  }

}

export default ({...options}) => new AuthorStore({...options,api:api()})
