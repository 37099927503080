import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'

import { ThemeButtons     } from '../components/theme/buttons'
import { author, genre    } from '../components'


const viewingFields = ['title','genre','author','abstract','intro']
const editingFields = ['genreID','authorID','title','abstract','intro','theme']

export const SeriesDetails = inject('seriesStore')(observer(({seriesStore}) => {

  if (editingType.typeID !== seriesStore.typeID) return null

  const data = {
    store     : seriesStore,
    dataStore : seriesStore,
    update    : seriesStore.update,
  }

  const fieldList = () => editingMode.isViewMode.get() ? viewingFields : editingFields

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="blog-details" type="series" fields={fieldList()}
        showLabels={editingMode.isEditMode.get()}
        renderers={{author, genre}}
    />
    <ThemeButtons store={seriesStore} />
  </React.Fragment></Provider>
}))
