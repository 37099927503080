import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore        } from 'sdc-auth-user'
import { Label            } from 'sdc-i18n-react'

import { Page, PageHeader } from '../layout'

import { AuthorList       } from './list'
import { AuthorDetails    } from './details'
import { SeasonMenu       } from './right'

export const Author = inject('authorStore')(observer(({authorStore}) => <Page left={SeasonMenu}>
  <PageHeader label="author" />
  <AuthorList />
  <AuthorDetails />
</Page>))
