import React from 'react'
import { observer    } from 'mobx-react'

import { userStore   } from 'sdc-auth-user'
import { editingMode } from 'sdc-cms-writing'

export const EditingMode = observer(({show=userStore.user.admin}) => <>
  {show && editingMode.isEditMode.get() && <span className="toggle pull-right" onClick={editingMode.setMode('view')}><i className="fa fa-eye" /></span>}
  {show && editingMode.isViewMode.get() && <span className="toggle pull-right" onClick={editingMode.setMode('edit')}><i className="fa fa-edit" /></span>}
</>)
