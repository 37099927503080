/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createReader = /* GraphQL */ `
  mutation CreateReader(
    $input: CreateReaderInput!
    $condition: ModelReaderConditionInput
  ) {
    createReader(input: $input, condition: $condition) {
      id
      owner
      name
      email
      subscribed
      verified
      admin
      createdAt
      updatedAt
    }
  }
`;
export const updateReader = /* GraphQL */ `
  mutation UpdateReader(
    $input: UpdateReaderInput!
    $condition: ModelReaderConditionInput
  ) {
    updateReader(input: $input, condition: $condition) {
      id
      owner
      name
      email
      subscribed
      verified
      admin
      createdAt
      updatedAt
    }
  }
`;
export const deleteReader = /* GraphQL */ `
  mutation DeleteReader(
    $input: DeleteReaderInput!
    $condition: ModelReaderConditionInput
  ) {
    deleteReader(input: $input, condition: $condition) {
      id
      owner
      name
      email
      subscribed
      verified
      admin
      createdAt
      updatedAt
    }
  }
`;
export const createBlog = /* GraphQL */ `
  mutation CreateBlog(
    $input: CreateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    createBlog(input: $input, condition: $condition) {
      id
      name
      owner
      size
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog(
    $input: UpdateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    updateBlog(input: $input, condition: $condition) {
      id
      name
      owner
      size
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog(
    $input: DeleteBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    deleteBlog(input: $input, condition: $condition) {
      id
      name
      owner
      size
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      blog
      title
      abstract
      content
      comments
      published
      protected
      pinned
      hidden
      createdAt
      updatedAt
      lastViewed
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      blog
      title
      abstract
      content
      comments
      published
      protected
      pinned
      hidden
      createdAt
      updatedAt
      lastViewed
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      blog
      title
      abstract
      content
      comments
      published
      protected
      pinned
      hidden
      createdAt
      updatedAt
      lastViewed
    }
  }
`;
export const createGenre = /* GraphQL */ `
  mutation CreateGenre(
    $input: CreateGenreInput!
    $condition: ModelGenreConditionInput
  ) {
    createGenre(input: $input, condition: $condition) {
      id
      owner
      genreID
      name
      desc
      theme
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
    }
  }
`;
export const updateGenre = /* GraphQL */ `
  mutation UpdateGenre(
    $input: UpdateGenreInput!
    $condition: ModelGenreConditionInput
  ) {
    updateGenre(input: $input, condition: $condition) {
      id
      owner
      genreID
      name
      desc
      theme
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
    }
  }
`;
export const deleteGenre = /* GraphQL */ `
  mutation DeleteGenre(
    $input: DeleteGenreInput!
    $condition: ModelGenreConditionInput
  ) {
    deleteGenre(input: $input, condition: $condition) {
      id
      owner
      genreID
      name
      desc
      theme
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
    }
  }
`;
export const createSeries = /* GraphQL */ `
  mutation CreateSeries(
    $input: CreateSeriesInput!
    $condition: ModelSeriesConditionInput
  ) {
    createSeries(input: $input, condition: $condition) {
      id
      owner
      genreID
      authorID
      tag
      title
      abstract
      intro
      theme
      url
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSeries = /* GraphQL */ `
  mutation UpdateSeries(
    $input: UpdateSeriesInput!
    $condition: ModelSeriesConditionInput
  ) {
    updateSeries(input: $input, condition: $condition) {
      id
      owner
      genreID
      authorID
      tag
      title
      abstract
      intro
      theme
      url
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSeries = /* GraphQL */ `
  mutation DeleteSeries(
    $input: DeleteSeriesInput!
    $condition: ModelSeriesConditionInput
  ) {
    deleteSeries(input: $input, condition: $condition) {
      id
      owner
      genreID
      authorID
      tag
      title
      abstract
      intro
      theme
      url
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const createBook = /* GraphQL */ `
  mutation CreateBook(
    $input: CreateBookInput!
    $condition: ModelBookConditionInput
  ) {
    createBook(input: $input, condition: $condition) {
      id
      owner
      genreID
      authorID
      seriesID
      tag
      title
      abstract
      intro
      index
      asin
      theme
      image
      pages
      pinned
      referral
      published
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      series {
        id
        owner
        genreID
        authorID
        tag
        title
        abstract
        intro
        theme
        url
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateBook = /* GraphQL */ `
  mutation UpdateBook(
    $input: UpdateBookInput!
    $condition: ModelBookConditionInput
  ) {
    updateBook(input: $input, condition: $condition) {
      id
      owner
      genreID
      authorID
      seriesID
      tag
      title
      abstract
      intro
      index
      asin
      theme
      image
      pages
      pinned
      referral
      published
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      series {
        id
        owner
        genreID
        authorID
        tag
        title
        abstract
        intro
        theme
        url
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteBook = /* GraphQL */ `
  mutation DeleteBook(
    $input: DeleteBookInput!
    $condition: ModelBookConditionInput
  ) {
    deleteBook(input: $input, condition: $condition) {
      id
      owner
      genreID
      authorID
      seriesID
      tag
      title
      abstract
      intro
      index
      asin
      theme
      image
      pages
      pinned
      referral
      published
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      series {
        id
        owner
        genreID
        authorID
        tag
        title
        abstract
        intro
        theme
        url
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      owner
      seasonID
      folder
      active
      createdAt
      updatedAt
      lastUsed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      owner
      seasonID
      folder
      active
      createdAt
      updatedAt
      lastUsed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      owner
      seasonID
      folder
      active
      createdAt
      updatedAt
      lastUsed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSeason = /* GraphQL */ `
  mutation DeleteSeason(
    $input: DeleteSeasonInput!
    $condition: ModelSeasonConditionInput
  ) {
    deleteSeason(input: $input, condition: $condition) {
      id
      owner
      genreID
      authorID
      bookID
      title
      tag
      abstract
      intro
      publishing
      published
      hidden
      theme
      schedule
      filepath
      scenelength
      size
      wordcount
      dailytarget
      phase
      alpha
      beta
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      book {
        id
        owner
        genreID
        authorID
        seriesID
        tag
        title
        abstract
        intro
        index
        asin
        theme
        image
        pages
        pinned
        referral
        published
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        series {
          id
          owner
          genreID
          authorID
          tag
          title
          abstract
          intro
          theme
          url
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      postID
      post {
        id
        blog
        title
        abstract
        content
        comments
        published
        protected
        pinned
        hidden
        createdAt
        updatedAt
        lastViewed
      }
      episodeID
      sender
      content
      createdAt
      updatedAt
      episode {
        id
        seasonID
        key
        index
        title
        abstract
        content
        uploaded
        fileID
        fileUUID
        publishing
        publishAt
        published
        alpha
        beta
        draft
        protected
        pinned
        hidden
        comments
        wordcount
        createdAt
        updatedAt
        lastViewed
        season {
          id
          owner
          genreID
          authorID
          bookID
          title
          tag
          abstract
          intro
          publishing
          published
          hidden
          theme
          schedule
          filepath
          scenelength
          size
          wordcount
          dailytarget
          phase
          alpha
          beta
          createdAt
          updatedAt
          lastUsed
        }
        notified
        notifiedAlpha
        notifiedBeta
      }
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      postID
      post {
        id
        blog
        title
        abstract
        content
        comments
        published
        protected
        pinned
        hidden
        createdAt
        updatedAt
        lastViewed
      }
      episodeID
      sender
      content
      createdAt
      updatedAt
      episode {
        id
        seasonID
        key
        index
        title
        abstract
        content
        uploaded
        fileID
        fileUUID
        publishing
        publishAt
        published
        alpha
        beta
        draft
        protected
        pinned
        hidden
        comments
        wordcount
        createdAt
        updatedAt
        lastViewed
        season {
          id
          owner
          genreID
          authorID
          bookID
          title
          tag
          abstract
          intro
          publishing
          published
          hidden
          theme
          schedule
          filepath
          scenelength
          size
          wordcount
          dailytarget
          phase
          alpha
          beta
          createdAt
          updatedAt
          lastUsed
        }
        notified
        notifiedAlpha
        notifiedBeta
      }
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      postID
      post {
        id
        blog
        title
        abstract
        content
        comments
        published
        protected
        pinned
        hidden
        createdAt
        updatedAt
        lastViewed
      }
      episodeID
      sender
      content
      createdAt
      updatedAt
      episode {
        id
        seasonID
        key
        index
        title
        abstract
        content
        uploaded
        fileID
        fileUUID
        publishing
        publishAt
        published
        alpha
        beta
        draft
        protected
        pinned
        hidden
        comments
        wordcount
        createdAt
        updatedAt
        lastViewed
        season {
          id
          owner
          genreID
          authorID
          bookID
          title
          tag
          abstract
          intro
          publishing
          published
          hidden
          theme
          schedule
          filepath
          scenelength
          size
          wordcount
          dailytarget
          phase
          alpha
          beta
          createdAt
          updatedAt
          lastUsed
        }
        notified
        notifiedAlpha
        notifiedBeta
      }
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      owner
      name
      url
      from
      createdAt
      updatedAt
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      owner
      name
      url
      from
      createdAt
      updatedAt
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      owner
      name
      url
      from
      createdAt
      updatedAt
    }
  }
`;
export const createAuthor = /* GraphQL */ `
  mutation CreateAuthor(
    $input: CreateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    createAuthor(input: $input, condition: $condition) {
      id
      owner
      name
      bio
      createdAt
      updatedAt
    }
  }
`;
export const updateAuthor = /* GraphQL */ `
  mutation UpdateAuthor(
    $input: UpdateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    updateAuthor(input: $input, condition: $condition) {
      id
      owner
      name
      bio
      createdAt
      updatedAt
    }
  }
`;
export const deleteAuthor = /* GraphQL */ `
  mutation DeleteAuthor(
    $input: DeleteAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    deleteAuthor(input: $input, condition: $condition) {
      id
      owner
      name
      bio
      createdAt
      updatedAt
    }
  }
`;
export const createBlock = /* GraphQL */ `
  mutation CreateBlock(
    $input: CreateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    createBlock(input: $input, condition: $condition) {
      id
      owner
      name
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateBlock = /* GraphQL */ `
  mutation UpdateBlock(
    $input: UpdateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    updateBlock(input: $input, condition: $condition) {
      id
      owner
      name
      text
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlock = /* GraphQL */ `
  mutation DeleteBlock(
    $input: DeleteBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    deleteBlock(input: $input, condition: $condition) {
      id
      owner
      name
      text
      createdAt
      updatedAt
    }
  }
`;
export const createSeason = /* GraphQL */ `
  mutation CreateSeason(
    $input: CreateSeasonInput!
    $condition: ModelSeasonConditionInput
  ) {
    createSeason(input: $input, condition: $condition) {
      id
      owner
      genreID
      authorID
      bookID
      title
      tag
      abstract
      intro
      publishing
      published
      hidden
      theme
      schedule
      filepath
      scenelength
      size
      wordcount
      dailytarget
      phase
      alpha
      beta
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      book {
        id
        owner
        genreID
        authorID
        seriesID
        tag
        title
        abstract
        intro
        index
        asin
        theme
        image
        pages
        pinned
        referral
        published
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        series {
          id
          owner
          genreID
          authorID
          tag
          title
          abstract
          intro
          theme
          url
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSeason = /* GraphQL */ `
  mutation UpdateSeason(
    $input: UpdateSeasonInput!
    $condition: ModelSeasonConditionInput
  ) {
    updateSeason(input: $input, condition: $condition) {
      id
      owner
      genreID
      authorID
      bookID
      title
      tag
      abstract
      intro
      publishing
      published
      hidden
      theme
      schedule
      filepath
      scenelength
      size
      wordcount
      dailytarget
      phase
      alpha
      beta
      createdAt
      updatedAt
      lastUsed
      genre {
        id
        owner
        genreID
        name
        desc
        theme
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
      }
      book {
        id
        owner
        genreID
        authorID
        seriesID
        tag
        title
        abstract
        intro
        index
        asin
        theme
        image
        pages
        pinned
        referral
        published
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        series {
          id
          owner
          genreID
          authorID
          tag
          title
          abstract
          intro
          theme
          url
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      author {
        id
        owner
        name
        bio
        createdAt
        updatedAt
      }
    }
  }
`;
export const createEpisode = /* GraphQL */ `
  mutation CreateEpisode(
    $input: CreateEpisodeInput!
    $condition: ModelEpisodeConditionInput
  ) {
    createEpisode(input: $input, condition: $condition) {
      id
      seasonID
      key
      index
      title
      abstract
      content
      uploaded
      fileID
      fileUUID
      publishing
      publishAt
      published
      alpha
      beta
      draft
      protected
      pinned
      hidden
      comments
      wordcount
      createdAt
      updatedAt
      lastViewed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      notified
      notifiedAlpha
      notifiedBeta
    }
  }
`;
export const updateEpisode = /* GraphQL */ `
  mutation UpdateEpisode(
    $input: UpdateEpisodeInput!
    $condition: ModelEpisodeConditionInput
  ) {
    updateEpisode(input: $input, condition: $condition) {
      id
      seasonID
      key
      index
      title
      abstract
      content
      uploaded
      fileID
      fileUUID
      publishing
      publishAt
      published
      alpha
      beta
      draft
      protected
      pinned
      hidden
      comments
      wordcount
      createdAt
      updatedAt
      lastViewed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      notified
      notifiedAlpha
      notifiedBeta
    }
  }
`;
export const deleteEpisode = /* GraphQL */ `
  mutation DeleteEpisode(
    $input: DeleteEpisodeInput!
    $condition: ModelEpisodeConditionInput
  ) {
    deleteEpisode(input: $input, condition: $condition) {
      id
      seasonID
      key
      index
      title
      abstract
      content
      uploaded
      fileID
      fileUUID
      publishing
      publishAt
      published
      alpha
      beta
      draft
      protected
      pinned
      hidden
      comments
      wordcount
      createdAt
      updatedAt
      lastViewed
      season {
        id
        owner
        genreID
        authorID
        bookID
        title
        tag
        abstract
        intro
        publishing
        published
        hidden
        theme
        schedule
        filepath
        scenelength
        size
        wordcount
        dailytarget
        phase
        alpha
        beta
        createdAt
        updatedAt
        lastUsed
        genre {
          id
          owner
          genreID
          name
          desc
          theme
          createdAt
          updatedAt
          lastUsed
        }
        book {
          id
          owner
          genreID
          authorID
          seriesID
          tag
          title
          abstract
          intro
          index
          asin
          theme
          image
          pages
          pinned
          referral
          published
          createdAt
          updatedAt
          lastUsed
        }
        author {
          id
          owner
          name
          bio
          createdAt
          updatedAt
        }
      }
      notified
      notifiedAlpha
      notifiedBeta
    }
  }
`;
