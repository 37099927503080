import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries       from '../graphql/queries'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { UpdatingStore } from 'sdc-cms-writing'
import { editingMode   } from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { ui            } from '../design'

import { awsDataToEntry } from 'sdc-data-models'

import api from './api'

const typeID = '4DqbYEYGccShR9jeIj7tSry0JLMvYhya'

class SeriesStore extends UpdatingStore {

  seriesID   = null

  @observable seriesByID = {}

  reloading = null

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'seriesID',
    })
    this.clearViewing = false

    this.createToEntry = awsDataToEntry('createSeries')
    this.updateToEntry = awsDataToEntry('updateSeries')

    subscribe('network-changed', speed => {
      this.reload()
    })

    subscribe('seriesID-entry-created',  this.seriesCreated)
    subscribe('seriesID-entry-updated',  this.seriesUpdated)
    subscribe('seriesID-entry-selected', this.seriesSelected)

    subscribe('series', id => {
      if (id && id !== this.seasonID) {
        console.log('series changed from',this.seriesID,'to',id)
        this.seriesID = id
        if (!this.selected.id) {
          setTimeout(() => {
            this.loadByID(id)
          },500)
        }
      }
    })
  }

  setEditingMode = event => {
    editingMode.setMode('edit')
  }
  setViewingMode = event => {
    editingMode.setMode('view')
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  loadByID = id => {
    const variables = {
      filter: {
        id : { eq : id },
      },
    }
    API.graphql({
      query: queries.listSeriess,
      variables,
      authMode: 'API_KEY',
    }).then(
      this.parseSelected
    ).catch(error => {
      console.error(error.errors?.[0]?.message || error)
    })
  }

  preselect = series => e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.select(series)(e)
  }

  parseAWS = deferred => action(data => {
    if (data?.listSeriess?.items) {
      const payload = data.listSeriess.items

      this.seriesByID = _.keyBy(payload, 'id')
      this.dataList = _.orderBy(payload, 'tag')
    }
    deferred.resolve(this.dataList)
  })

  @action
  parseSelected = response => {
    const payload = response?.data?.listSeriess?.items
    if (payload) {
      if (!this.selected.id) {
        this.preselect(payload[0])()
      }
    }
  }

  seriesSelected = series => {
    if (series) {
      ui.switchTo(series.theme)()
      this.setViewingMode()
      ui.switchTo(this.selected.theme)()
    }
  }

  seriesUpdated = series => {
    if (series) {
      this.patch(series)
      ui.switchTo(series.theme)()
    }
  }

  updateTheme = theme => {
    this.patch({
      id : this.selected.id,
      theme,
    })
  }

  setLastUsed = (series,ts) => action(() => {
    if ((series.lastUsed || 0) + 10 < ts) {
      this.updateEntryField(this.selected,'lastUsed',ts)
    }
  })

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateSeries(response?.data?.updateSeries)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateSeries = series => {
    if (series) {
      this.dataList = update(this.dataList)(series)
      if (this.selected.id === series.id) {
        this.selected = series
        ui.switchTo(this.selected.theme)()
      }
    }
  }

}

export default ({...options}) => new SeriesStore({...options,api:api()})
