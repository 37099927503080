import { ContentApi }    from 'sdc-cms-client'
import { UpdatingStore } from 'sdc-cms-writing'

const typeID = '8MfjLQ2ydhRCYsw0eN0aUrBnG9hmxOuE'


export class UsersStore extends UpdatingStore {

  constructor(options) {
    super({
      ...options,
      typeID,
    })
    this.list({})()
  }

}

export default ({cmsBase,...options}) => new UsersStore({...options,api:ContentApi({cmsBase,typeID})})
