import React from 'react'
import { observer      } from 'mobx-react'

import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'

import { Page          } from '../layout'

import { BlogList      } from './list'
import { BlogDetails   } from './details'

export const Blog = observer(() => <Page>
  <h3 className="blog">
    {editingMode.isEditMode.get() && <span className="toggle pull-right" onClick={editingMode.setMode('view')}><i className="fa fa-eye" /></span>}
    {editingMode.isViewMode.get() && <span className="toggle pull-right" onClick={editingMode.setMode('edit')}><i className="fa fa-edit" /></span>}
    <Label value="nav.blog" />
  </h3>
  <BlogList />
  <BlogDetails />
</Page>)
