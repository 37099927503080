import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label     } from 'sdc-i18n-react'

import { episodeToListEntry } from '../episode/helpers'

import { mode      } from '../layout/mode'
import { Label4    } from '../components'


export const SeasonMenu = inject('episodeStore','seasonStore','userStore')(observer(({episodeStore,seasonStore,userStore}) => {

  if (mode.state !== 'menu') return null;

  const visibleOnPinned = episode => (episodeStore.showHidden || !episode.hidden) && (episode.season?.tag !== seasonStore.selected.tag)
  const visibleOnMenu = episode => episodeStore.showHidden || !episode.hidden || userStore.user.admin
  const isOwner = userStore.user.admin && userStore.user.id === seasonStore.selected.owner

  return <div className="col-md-2 side-panel post-suche">
    {false && episodeStore.gepinnt.filter(visibleOnPinned).length > 0 && <Label4 value={seasonStore.selected.id ? 'episodes.pinnedOther' : 'episodes.pinned'} />}
    {false && episodeStore.gepinnt.filter(visibleOnPinned).map(episodeToListEntry(episodeStore))}
    {seasonStore.selected.id && <h5 style={{opacity: 0.25, marginBottom: '-12px', marginTop: '12px'}}> Alle Episoden aus </h5>}
    {seasonStore.selected.id && <h4> {seasonStore.selected.title} </h4>}
    {episodeStore.byIndex.filter(visibleOnMenu).map(episodeToListEntry(episodeStore,false,userStore.user.admin))}
  </div>

}))
