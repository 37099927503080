import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { EntryFieldsList  } from 'sdc-cms-editing'

import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'

import { mode             } from '../layout/mode'

const viewingFields = ['name']
const editingFields = ['name','email','subscribed']

export const ReaderDetails = inject('readerStore')(observer(({readerStore}) => {

  if (!readerStore.selected.id) return null
  if (editingType.typeID !== readerStore.typeID) return null

  const data = {
    store     : readerStore,
    dataStore : readerStore,
    update    : readerStore.update,
  }

  const fieldList = () => editingMode.isViewMode.get() ? viewingFields : editingFields

  return <Provider {...data}>
    <EntryFieldsList className="episode-details" type="reader" fields={fieldList()}
        showLabels={editingMode.isEditMode.get()}
        onClick={readerStore.handleClick}
        renderers={{
          subscribed: ({store}) => <>{store.selected?.series && <h4>Buch {store.selected.index} der Serie <a href={'/series/'+store.selected?.series?.id}>{store.selected?.series?.title}</a></h4>}</>,
        }}
    />
  </Provider>
}))

