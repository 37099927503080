import   React      from 'react'
import   cn         from 'classnames'
import { observer } from 'mobx-react'

import { Button   } from 'sdc-react-basics'

import { ui       } from '../../design'

export const ThemeButton = observer(({store,shade,theme}) => {

  return <Button
    className={cn('theme-selector',shade+theme,
      (shade+theme) !== store.selected.theme && 'inactive',
      (shade+theme) === store.selected.theme && 'selected',
    )}
    title={'blog.theme.'+shade+theme}
    onClick={() => store.updateTheme(shade+theme)}
  >{theme}</Button>
})
