import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore } from 'sdc-auth-user'
import { Label     } from 'sdc-i18n-react'

import { episodeToListEntry } from './helpers'

import { Label4     } from '../components'
import { SignIn     } from '../cognito/signin'
import { SignUp     } from '../cognito/signup'
import { BookCard   } from '../book/card'

export const LeftMenu = inject('seasonStore','episodeStore','bookStore')(observer(({seasonStore,episodeStore,bookStore}) => {

  const visibleOnPinned = episode => (episodeStore.showHidden || !episode.hidden) && (episode.season?.tag !== seasonStore.selected.tag)
  const visibleOnMenu = episode => episodeStore.showHidden || !episode.hidden

  return <div className="col-md-2 side-panel left-side post-suche custom-scrollbar">
    {userStore.user.id && episodeStore.gepinnt.filter(visibleOnPinned).length > 0 && <Label4 value={seasonStore.selected.id ? 'episodes.pinnedOther' : 'episodes.pinned'} />}
    {userStore.user.id && episodeStore.gepinnt.filter(visibleOnPinned).map(episodeToListEntry(episodeStore))}
    {!(episodeStore.selected.protected && !userStore.user.id) && bookStore.featured.id && !userStore.user.admin && <BookCard book={bookStore.featured} />}
    {episodeStore.selected.protected && <SignIn />}
    {episodeStore.selected.protected && <SignUp />}
  </div>

}))
