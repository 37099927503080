import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React    from 'react'
import ReactDOM from 'react-dom'
import qs       from 'qs'

import { AppContainer } from 'react-hot-loader'

import { autorun, configure } from 'mobx'
import { Provider } from 'mobx-react'
import { Router } from 'react-router'

//import Modal from 'react-modal'

import './custom.scss'
import 'font-awesome/css/font-awesome.css'

//import 'react-grid-layout/css/styles.css'
//import 'react-resizable/css/styles.css'

import { userStore } from 'sdc-auth-user'

import { Main } from './main'

import { history, routingStore } from './history'

import { uiBase }   from './env/constants'

import * as otherStores from './stores'


// import registerServiceWorker from './registerServiceWorker'


configure({
  enforceActions : 'always',
})

//Modal.setAppElement('#root')


const stores = {
  routing: routingStore,
  ...otherStores
}

// let authenticated = false

// autorun(() => {
//   if (userStore.state.authenticated && !authenticated) {
//     console.log(`going back in history, user = ${userStore.user.name}`)
//     //history.goBack()
//   }
//   authenticated = userStore.state.authenticated
// })

const search = window.location.search
if (search) {
  const params = qs.parse(search.replace(/^\?/,''))
  console.log('window location:', params)
  if (params.username) {
    stores.cognitoStore.signUp(params)
  }
  if (params.signin) {
    stores.cognitoStore.signIn(params)
  }
}

history.listen((location,action) => {
  console.log('location history:', action, location.pathname)
  if (location.pathname === '/post') {
    //stores.postStore.selectMRU()
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 1000)
  }
  // if (location.pathname === '/') {
  //   //stores.postStore.selectMRU()
  //   setTimeout(() => {
  //     window.dispatchEvent(new Event('resize'))
  //   }, 1000)
  // }
  // if (location.search) {
  //   const params = qs.parse(location.search.slice(1))
  //   console.log(params)
  //   if (params.project) {
  //     stores.projectsStore.selectByID(params.project)
  //   }
  //   if (params.sequence) {
  //     const sequence = parseInt(params.sequence, 10)
  //     if (stores.scenesStore.sequence !== sequence) {
  //       stores.scenesStore.selectSequence(sequence)()
  //     }
  //   }
  // }
  //sendPageView(location.pathname)
})
// history.push('/post')

const render = Component =>
ReactDOM.render(
  <AppContainer>
    <Provider {...stores} >
      <Router history={history} path={uiBase} >
    	  <Component />
      </Router>
    </Provider>
  </AppContainer>,
  document.getElementById('root')
)

render(Main)

if (module.hot) {
  module.hot.accept('./main', () => render(Main))
}

//registerServiceWorker(appState.setUpdateAvailable)
