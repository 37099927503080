import React from 'react'
import { observer    } from 'mobx-react'

import { Label       } from 'sdc-i18n-react'

import { Trigger     } from '../../components'
import { EditingMode } from '../../components'

export const PageHeader = observer(({children,label,editable}) =>
<h4 className="blog">
  <Trigger topic={label} />
  <EditingMode show={editable} />
  <Label value={`nav.${label}${children ? '' : 's'}`} /> {children}
</h4>)
