import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'


export const AuthorDetails = inject('authorStore')(observer(({authorStore}) => {

  if (editingType.typeID !== authorStore.typeID) return null

  const data = {
    store     : authorStore,
    dataStore : authorStore,
    update    : authorStore.update,
  }

  return <Provider {...data}>
    <EntryFieldsList className="blog-details" type="author" fields={['name','bio']}
        showLabels={editingMode.isEditMode.get()}
    />
  </Provider>
}))
