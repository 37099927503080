import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'

import { ThemeButtons     } from '../components/theme/buttons'
import { author, genre    } from '../components'


const viewingFields = ['title','genre','author','book','abstract','intro']
const editingFields = ['tag','genreID','authorID','bookID','schedule','published','filepath','scenelength','title','abstract','intro','theme','hidden','alpha','beta']

export const SeasonDetails = inject('seasonStore')(observer(({seasonStore}) => {

    if (editingType.typeID !== seasonStore.typeID) return null

    const data = {
    store     : seasonStore,
    dataStore : seasonStore,
    update    : seasonStore.update,
  }

  const fieldList = () => editingMode.isViewMode.get() ? viewingFields : editingFields

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="blog-details" type="season" fields={fieldList()}
        showLabels={editingMode.isEditMode.get()}
        renderers={{author,genre}}
    />
    <ThemeButtons store={seasonStore} />
  </React.Fragment></Provider>
}))
