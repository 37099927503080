import { action, observable } from 'mobx'

export const shades = ['dark','light']
export const hues   = ['Gray','Blue','BluePink','Pink','RedPink','Red','Orange','Yellow','YellowGreen','Green','CyanGreen','Cyan','BlueCyan']

const defaultTheme = 'darkCyan'

class UI {

  @observable theme = ''

  constructor() {
    this.switchTo()()
  }

  switchTo = (theme = defaultTheme) => action(e => {
    if (e?.preventDefault) {
      e.preventDefault()
    }

    if (this.theme !== theme) {
      console.log('switching to theme', theme)

      this.theme = theme || defaultTheme

      document.body.className = 'mb mb-main '+this.theme
    }
  })

}

export const ui = new UI()
