import React from 'react'
import { inject, observer} from 'mobx-react'
import cn from 'classnames'

import { Col, Card, CardTitle, CardSubtitle, CardHeader, CardBody, CardFooter } from 'reactstrap'

import { BookTitle } from './title'

const width = window.innerWidth > 1920 ? 320 : 160

export const BookCard = inject('seriesStore','bookStore','userStore')(observer(({seriesStore,bookStore,userStore,book,index}) =>
<a target="_blank" rel="noopener noreferrer" className="book-link"
    href={`https://www.amazon.de/dp/${book.asin}?tag=magische-bucher-21`} className="amazon-link">
<div style={{marginTop: '12px', marginBottom: '6px', paddingLeft: '4px', paddingRight: '4px', cursor: 'pointer'}}>
  <Card className={'book '+(book.theme || 'darkCyan')} style={{height: '100%'}}>
    <CardHeader style={{fontSize: '125%', textAlign: 'center'}}>
      {book.referral && <div style={{textAlign: 'center', color: '#e47911'}}>Buchempfehlung</div>}
      {book.pinned && userStore.user.admin && <span className="pull-right pinned-true"><i className="fa fa-thumb-tack" /></span>}
      {book.title}
    </CardHeader>
    <CardBody style={{fontSize: '75%', textAlign: 'justify'}}>
      {book.asin && book.image && <CardTitle>
        <img style={{width: '100%'}} src={`https://images-eu.ssl-images-amazon.com/images/I/${book.image}._SL${width}_.jpg`} />
      </CardTitle>}
      {book.abstract}
    </CardBody>
    {(book.series?.id || book.asin) && <CardFooter style={{textAlign: 'center'}}>
      {book.series?.id && <div onClick={seriesStore.preselect(book.series)}><div style={{opacity: 0.5}}>Buch {book.index} aus der Reihe</div> {book.series?.title}</div>}
      {book.asin && <div style={{color: '#e47911'}}>Erhältlich auf Amazon!</div>}
      {book.asin && <div style={{opacity: .5, fontSize: '70%'}}>Dies ist ein Partner-Link. Wenn ihr über diesen Link etwas bestellt, unterstützt ihr damit diese Website.</div>}
    </CardFooter>}
  </Card>
</div></a>))
