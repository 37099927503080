import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'

const columns = (store) => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.create({
      owner : userStore.user.id,
    }),
  }),
  column.forField('author.name', { width: 200 }),
  column.forField('genre.name', { width: 200 }),
  column.forField('title'),
])

export const SeriesList = inject('seriesStore')(observer(({seriesStore}) =>
<DataTable store={seriesStore}
  columns={columns(seriesStore)}
  sorting={[{id : 'genre.name'},{id : 'title'}]}
/>))
