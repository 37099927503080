import React from 'react'
import { inject, observer } from 'mobx-react'

import { Page          } from '../layout'
import { PageHeader    } from '../layout'

import { BlockList     } from './list'
import { BlockDetails  } from './details'
import { SeasonMenu    } from './right'

export const Block = inject('blockStore')(observer(({blockStore}) => <Page left={SeasonMenu}>
  <PageHeader label="block" />
  <BlockList />
  <BlockDetails />
</Page>))
