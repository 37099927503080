import React from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { Button    } from 'sdc-react-basics'

import { Label     } from 'sdc-i18n-react'
import { userStore } from 'sdc-auth-user'

import { episodeToListEntry } from './helpers'

import { mode      } from '../layout/mode'
import { Label4    } from '../components'


export const EpisodeMenu = inject('episodeStore','seasonStore')(observer(({episodeStore,seasonStore}) => {

  if (mode.state !== 'menu') return null;

  const visibleOnPinned = episode => (episodeStore.showHidden || !episode.hidden) && (episode.season?.tag !== seasonStore.selected.tag)
  const visibleOnMenu = episode => episodeStore.showHidden || !episode.hidden || userStore.user.admin
  const isOwner = userStore.user.admin && userStore.user.id === seasonStore.selected.owner

  return <div className="col-md-2 side-panel post-suche">
    {!userStore.user.id && episodeStore.gepinnt.filter(visibleOnPinned).length > 0 && <Label4 value={seasonStore.selected.id ? 'episodes.pinnedOther' : 'episodes.pinned'} />}
    {!userStore.user.id && episodeStore.gepinnt.filter(visibleOnPinned).map(episodeToListEntry(episodeStore))}
    { userStore.user.admin && seasonStore.selected.id && <h5 style={{opacity: 0.25, marginBottom: '-12px', marginTop: '12px'}}> Alle Episoden aus </h5>}
    {!userStore.user.admin && seasonStore.selected.id && <h5 style={{opacity: 0.25, marginBottom: '-12px', marginTop: '12px'}}> Alle verfügbaren Episoden aus </h5>}
    {seasonStore.selected.id && <h4 className="selectable" onClick={seasonStore.preselect(seasonStore.selected)}> {seasonStore.selected.title} </h4>}
    <div className="text-center">
      {(seasonStore.selected.schedule || 1)+' neue Episode(n) pro Woche'}
    </div>
    {episodeStore.byIndex4view.filter(visibleOnMenu).map(episodeToListEntry(episodeStore,false,userStore.user.admin,true))}
  </div>

}))
