import React from 'react'
import { inject,observer } from 'mobx-react'

import { Form, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap'

import { userStore } from 'sdc-auth-user'

import { Label4     } from '../components'

import './registration.scss'

export const SignUp = inject('cognitoStore')(observer(({cognitoStore}) => userStore.user.id || cognitoStore.known ? null : <div className="registration">
  <Label4 value="registration" />
  <div>
    Registriere dich, um Zugang zu geschützten Inhalten zu erhalten.
  </div>
  <Form>
    <InputGroup>
      <span className="input-group-text fa fa-user-o" style={{paddingTop: '10px'}} />
      <Input type="text"  placeholder="Benutzername" value={cognitoStore.name}  onChange={cognitoStore.updateName} />
    </InputGroup>
    <InputGroup>
      <InputGroupAddon addonType="prepend">@</InputGroupAddon>
      <Input type="email" placeholder="E-Mail-Adresse" value={cognitoStore.email} onChange={cognitoStore.updateEmail} />
    </InputGroup>
    <div>
      <i className="fa fa-check fa-2x pull-left" style={{opacity: 0.5, color: '#090'}} /> Freier Zugang zu allen kostenlosen Inhalten im geschützten Bereich
    </div>
    <div>
      <i className="fa fa-check fa-2x pull-left" style={{opacity: 0.5, color: '#090'}} /> Newsletter mit Neuigkeiten und exklusiven Vorab-Episoden
    </div>
    <InputGroup>
      <Button onClick={cognitoStore.signUp} style={{margin: 'auto', paddingLeft: '1rem', paddingRight: '1rem'}}>Registrieren</Button>
    </InputGroup>
  </Form>
  <ul className="disclaimer">
    <li>Du erhälst ein bis zwei E-Mails pro Woche.</li>
    <li>Du kannst den Newsletter jederzeit abbestellen.</li>
    <li>Wir geben deine Daten nicht weiter.</li>
    <li>Kein Spam!</li>
  </ul>
</div>))
