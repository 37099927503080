import React from 'react'
import { inject,observer } from 'mobx-react'

import { Form, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap'

import { userStore } from 'sdc-auth-user'

import { Label4     } from '../components'

import './registration.scss'

export const SignIn = inject('cognitoStore')(observer(({cognitoStore}) => userStore.user.id || !cognitoStore.known || cognitoStore.pending ? null : <div className="registration">
  <Label4 value="anmeldung" />
  <div>
    Melde dich erneut an, um wieder Zugang zu den geschützten Inhalten zu erhalten.
  </div>
  <Form>
    {!cognitoStore.known && <InputGroup>
      <span className="input-group-text fa fa-user-o" style={{paddingTop: '10px'}} />
      <Input type="text"  placeholder="Benutzername" value={cognitoStore.name}  onChange={cognitoStore.updateName} />
    </InputGroup>}
    <InputGroup>
      <InputGroupAddon addonType="prepend">@</InputGroupAddon>
      <Input type="email" placeholder="E-Mail-Adresse" value={cognitoStore.email} onChange={cognitoStore.updateEmail} />
    </InputGroup>
    <InputGroup>
      <Button onClick={cognitoStore.signUp} style={{margin: 'auto', paddingLeft: '1rem', paddingRight: '1rem'}}>Erneut anmelden</Button>
    </InputGroup>
  </Form>
  <ul className="disclaimer">
    <li>Du erhälst per E-Mail einen Link zur sicheren Anmeldung.</li>
  </ul>
</div>))
