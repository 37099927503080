import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'

const columns = (store) => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.create({
      owner : userStore.user.id,
    }),
  }),
  column.forField('name',       { width: 150 }),
  column.forField('desc'),
])

export const GenreList = inject('genreStore')(observer(({genreStore}) =>
<DataTable store={genreStore}
  columns={columns(genreStore)}
/>))
