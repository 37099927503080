import React from 'react'
import cn    from 'classnames'
import md5   from 'md5'
import { inject, observer } from 'mobx-react'

import { Link, NavLink } from 'react-router-dom'
import { Nav, Input, InputGroup, InputGroupAddon } from 'reactstrap'


import { userStore     } from 'sdc-auth-user'
import { UserName      } from 'sdc-auth-react'
import { editingType      } from 'sdc-cms-writing'

import { AppVersion, Button   } from 'sdc-react-basics'
import { Label            } from 'sdc-i18n-react'


import { uiBase           } from '../env/constants'

import { mode             } from '../layout/mode'

import { BlogMenu         } from '../blog/menu'
import { SeasonMenu       } from '../season/menu'


const uiAvatars = encodeURI('https://ui-avatars.com/api/')


export const NavBar = inject('genreStore','authorStore','blockStore','postStore','episodeStore','cognitoStore','bookStore','seasonStore','seriesStore')(
    observer(({genreStore,authorStore,blockStore,postStore,episodeStore,cognitoStore,bookStore,seasonStore,seriesStore}) => mode.state === 'menu' ?
<nav className="navbar navbar-expand-lg navbar-static-top">

  <div className="navbar-header mr-auto">
    <Link className="navbar-brand" to={uiBase}>
      <span><i className="fa fa-fw fa-home" /></span>
      <Label className="app-name" value="app.name" />
    </Link>
  </div>

  {false && userStore.user.admin && <ul className="nav navbar-nav mr-auto">
    <li className="nav-item">
      <NavLink className="nav-link" to={userStore.user.admin ? '/blog' : '/post'}>
        <i className={'fa fa-archive'}></i> <Label value="nav.blogs" />
      </NavLink>
    </li>
    <BlogMenu nav />
    <li className="nav-item">
      <NavLink className="nav-link" to="/post">
        <i className={'fa fa-files-o'}></i> {postStore.dataList.length} <Label value="nav.posts" />
      </NavLink>
    </li>
  </ul>}

  {userStore.user.admin && <ul className="nav navbar-nav mr-auto">
    <li className="nav-item">
      <NavLink className="nav-link" to={'/genre/'+genreStore.selected.id}>
        <i className={'fa fa-edit'}></i> <Label value="nav.genres" />
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink className="nav-link" to={'/author/'+authorStore.selected.id}>
        <i className={'fa fa-user-o'}></i> <Label value="nav.authors" />
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink className="nav-link" to={'/block/'+blockStore.selected.id}>
        <i className={'fa fa-file-o'}></i> <Label value="nav.blocks" />
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink className="nav-link" to={'/series/'+seriesStore.selected.id}>
        <i className={'fa fa-archive'}></i> <Label value="nav.series" />
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink className="nav-link" to="/book">
        <i className={'fa fa-book'}></i> <Label value="nav.books" />
      </NavLink>
    </li>
  </ul>}

  {false && userStore.user.admin && <InputGroup className="post-suche mr-auto">
    {false && <InputGroupAddon addonType="prepend">
      <Button icon="eye-slash" title="toggle.hidden" className={cn(
      postStore.showHidden && 'hidden',
      !postStore.showHidden && 'visible',
    )} onClick={postStore.toggleVisibilityFilter} />
    </InputGroupAddon>}
    <Input value={postStore.suche}
      onChange={postStore.filter} placeholder="Suche..."
    />
    <InputGroupAddon addonType="append">
      <Button icon="times" title="search.clear" disabled={!postStore.suche} onClick={postStore.clearSearch} />
    </InputGroupAddon>
  </InputGroup>}

  <ul className="nav navbar-nav mr-auto">
    <li className="nav-item">
      <NavLink className="nav-link" to={'/season/'+seasonStore.selected.id}>
        <i className={'fa fa-bars'}></i> <Label value="nav.seasons" />
      </NavLink>
    </li>
    <SeasonMenu nav />
    {seasonStore.selected.id && <li className="nav-item">
      <NavLink className="nav-link" to={`/episode/${episodeStore.selected.id || (episodeStore.byIndex.length && episodeStore.byIndex?.[0]?.id)}`}>
        <i className={'fa fa-envelope-o'}></i> {episodeStore.dataList.length} <Label value="nav.episodes" />
      </NavLink>
    </li>}
  </ul>


  <AppVersion />

  {userStore.user.email && <NavLink className="nav-link" style={{padding: 0}} to={'/settings'}>
    <img src={`https://www.gravatar.com/avatar/${md5(userStore.user.email)}?s=50&d=${uiAvatars}/${userStore.user.name}/50/333/ccc/3`} />
  </NavLink>}
  <ul className="nav navbar-nav">
    {false && <li className="nav-item"><a className="nav-link" href="https://docs.zettelkasten.ninja" target="_new"><i className="fa fa-question-circle-o" /> <Label value="sites.docs" /></a></li>}
    { userStore.user.id && <li className="nav-item"><Button className="nav-link"    icon="power-off"    onClick={cognitoStore.signOut} /></li>}
  </ul>

</nav>
: null))
