import { API, graphqlOperation } from 'aws-amplify'
import * as subscriptions from '../graphql/subscriptions'
import * as queries       from '../graphql/queries'

import { userStore     } from 'sdc-auth-user'
import { editingMode   } from 'sdc-cms-writing'
import { UpdatingStore } from 'sdc-cms-writing'

export class AmplifyStore extends UpdatingStore {

  constructor({
    owned,
    ...options
  }) {
    super(options)
    this.owned = owned
  }

  subscription = {}

  subscribeTo = (event,ownerID) => {
    if (this.subscription[event]) {
      try {
        this.subscription[event].unsubscribe()
      } catch(error) {
        console.error(error)
      }
    }

    const owner = userStore.user.id || ownerID
    const query = this.owned ? { owner } : undefined

    if (this.owned && !query) return;

    this.subscription[event] = API.graphql({
      query     : subscriptions[event],
      variables : query,
      authMode  : 'API_KEY',
    }).subscribe({
      next  : update => this[event](update.value?.data?.[event]),
      error : error  => console.error(error.error?.errors?.[0]?.message),
    })
  }

  setEditingMode = event => {
    editingMode.setMode('edit')
  }
  setViewingMode = event => {
    editingMode.setMode('view')
  }

  listBy = async (query,filter,callback) => {
    let nextToken = null
    let payload = []

    do {
      const response = await API.graphql({
        query : queries[query],
        variables : {
          filter,
          limit: 1000,
          nextToken,
        },
        authMode: 'API_KEY',
      })
      const data = response.data[query]
      payload   = payload.concat(data.items)
      nextToken = data.nextToken
    } while (nextToken)

    callback(payload)
  }

}
