import { action, autorun, observable, extendObservable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries       from '../graphql/queries'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { requiredParam } from 'sdc-utilities'
import { editingMode   } from 'sdc-cms-writing'

import { awsDataToEntry } from 'sdc-data-models'

import { AmplifyStore  } from '../amplify/store'

import { ui            } from '../design'

import api from './api'

class ReaderStore extends AmplifyStore {

  constructor({
    ...options
  }) {
    super({
      ...options,
      typeID: 'yUdLImFH17RKmiQpNVk9X7MMgofxl1Wg',
      name: 'reader',
    })
    this.clearViewing = false

    autorun(() => {
      if (userStore.user.id) {
        setTimeout(this.loadReader, 10)
      }
    })

    this.createToEntry = awsDataToEntry('createReader')
    this.updateToEntry = awsDataToEntry('updateReader')

    // subscribe('reader-entry-selected', this.readerSelected)
    // subscribe('reader-entry-updated',  this.readerUpdated)

    subscribe('reader', id => {
      if (id && this.editingStore.selected.id !== this.selected.id) {
        this.select(this.selected)()
        ui.switchTo()()
        editingMode.setMode('edit')()
      }
    })
  }

  loadReader = () => {
    if (!userStore.user.id) return;
    if (userStore.user.id === this.selected.owner) return;

    const variables = {
      filter: { owner: { eq: userStore.user.id } },
    }
    API.graphql({
      query: queries.listReaders,
      variables,
    }).then(
      this.parseReader
    ).catch(error => {
      console.error(error.errors?.[0]?.message || error)
    })
  }

  @action
  parseReader = response => {
    const payload = response?.data?.listReaders?.items
    if (payload?.length) {
      this.select(payload[0])()
    } else {
      this.create({
        email : userStore.user.email,
        name  : userStore.user.name,
        subscribed : true,
      })()
    }
  }

  @action
  toggleSubscribed = e => {
    this.updateEntryField(this.selected,'subscribed',!this.selected.subscribed)
  }

  @action
  updateName = e => {
    this.updateEntryField(this.selected,'name',e.target.value)
  }

}

export default ({...options}) => new ReaderStore({...options,api:api()})
