import React from 'react'
import { observer  } from 'mobx-react'

import { Container, Row, Col } from 'reactstrap'

import { BookPanel   } from '../panels/book'
import { SignupPanel } from '../panels/signup'

export * from './header'


export const Page = observer(({children, left: LeftPanel = SignupPanel, right: RightPanel = BookPanel}) => <Container fluid>
  <Row>
    <LeftPanel />
    <Col className="sec-content-panel-wrapper custom-scrollbar">
      <div className="sec-content-panel">
        {children}
      </div>
    </Col>
    <RightPanel />
  </Row>
</Container>)
