import   React           from 'react'
import   cn              from 'classnames'
import { inject, observer } from 'mobx-react'

import { Button as RSButton } from 'reactstrap'
import { Button        } from 'sdc-react-basics'

import { userStore     } from 'sdc-auth-user'
import { Label         } from 'sdc-i18n-react'
import { CreateButton  } from 'sdc-react-basics'

import { ui            } from '../design'
import { Page          } from '../layout'
import { ModeButton    } from '../layout/mode/button'
import { H3, Trigger   } from '../components'
import { EditingMode   } from '../components'

import { LeftMenu      } from './left'
import { EpisodeMenu   } from './menu'

import { EpisodeTitle   } from './title'
import { EpisodeDetails } from './details'

export const Episode = inject('seasonStore','episodeStore')(observer(({seasonStore,episodeStore}) => <Page
    left={!userStore.user.admin ? EpisodeMenu : LeftMenu}
    right={userStore.user.admin ? EpisodeMenu : LeftMenu}>
  <Trigger topic="episode" />
  {seasonStore.selected.id && <H3 className="episode">
    <span>
      <EpisodeTitle episode={episodeStore.selected} />
    </span>
    {userStore.user.admin && <Button as="span" icon="eye-slash" title="toggle.visibility" className={cn(
      'float-left',
      episodeStore.selected.hidden && 'hidden',
      !episodeStore.selected.hidden && 'visible',
    )} onClick={episodeStore.toggleVisibility} />}
    <EditingMode />
    {userStore.user.admin && <CreateButton as="span" className="float-right action-create" onClick={episodeStore.createEpisode} title="episode.create" />}
  </H3>}
  <h5 style={{textAlign: 'center'}} className="clearfix">
    {episodeStore.byIndex.indexOf(episodeStore.selected) > 0 && <RSButton outline className="pull-left nav-button" onClick={episodeStore.navigate(-1)}><i className="fa fa-caret-left" style={{minWidth: '.75rem'}} /></RSButton>}
    {episodeStore.byIndex.indexOf(episodeStore.selected) < episodeStore.byIndex.length-1 && <RSButton outline className="pull-right nav-button" onClick={episodeStore.navigate(+1)}><i className="fa fa-caret-right" style={{minWidth: '.75rem'}} /></RSButton>}
    <span style={{opacity: 0.5, marginTop: '6px', display: 'inline-block'}}> Episode {episodeStore.selected.index} aus der Staffel </span>
    <span className="selectable" onClick={seasonStore.preselect(episodeStore.selected.season)}>{episodeStore.selected.season?.title}</span>
    {seasonStore.selected.author && <span style={{opacity: 0.5, marginTop: '6px', display: 'inline-block'}}> von {seasonStore.selected.author.name}</span>}
  </h5>
  <EpisodeDetails />
  {!userStore.user.id && episodeStore.selected.protected && <h5 className="episode protected">
    Diese Episode liegt im geschützten Bereich. Registriere dich, um Zugang zu allen kostenlosen Inhalten zu erhalten.
  </h5>}
</Page>))
