import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'

import { ThemeButtons     } from '../components/theme/buttons'
import { author, genre    } from '../components'


const viewingFields = ['series','title','author','genre','abstract','intro']
const editingFields = ['referral','pinned','tag','seriesID','index','asin','image','authorID','title','abstract','intro']


export const BookDetails = inject('bookStore')(observer(({bookStore}) => {

    if (editingType.typeID !== bookStore.typeID) return null

    const data = {
    store     : bookStore,
    dataStore : bookStore,
    update    : bookStore.update,
  }

  const fieldList = () => editingMode.isViewMode.get() ? viewingFields : editingFields

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="blog-details" type="book"
        fields={fieldList()}
        showLabels={editingMode.isEditMode.get()}
        renderers={{
          author,
          series: ({store}) => <>{store.selected?.series && <h4>Buch {store.selected.index} der Serie <a href={'/series/'+store.selected?.series?.id}>{store.selected?.series?.title}</a></h4>}</>,
          genre,
        }}
    />
    <ThemeButtons store={bookStore} />
  </React.Fragment></Provider>
}))
