import React from 'react'
import cn    from 'classnames'
import { inject, observer } from 'mobx-react'

import Amplify              from 'aws-amplify'

import { Route, Router    } from 'react-router-dom'

import moment from 'moment'
import 'moment/locale/de'

//import MomentLocalizer from 'react-widgets-moment'

import { ToastContainer } from 'react-toastify'

import { userStore        } from 'sdc-auth-user'

import { Authenticated    } from 'sdc-auth-react'

import { editingConfig, VALUES_PLAIN } from 'sdc-data-models'

import { uiBase   } from './env/constants'
import { ui       } from './design'

import { NavBar   } from './nav/bar'

import { Home     } from './home'
import { Reader   } from './reader'
import { Author   } from './author'
import { Block    } from './block'
import { Blog     } from './blog'
import { Post     } from './post'
import { Genre    } from './genre'
import { Series   } from './series'
import { Book     } from './book'
import { Season   } from './season'
import { Episode  } from './episode'

import { history } from './history'

import awsDefaults from './aws-exports'

import 'react-toastify/dist/ReactToastify.css'

console.log('switching to locale "%s"',moment.locale('de'))
//momentLocalizer()

const awsConfig = {
  ...awsDefaults,
  aws_appsync_graphqlEndpoint: 'https://lncobnxiybgyfio6lb4c7zsx64.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  // aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-yuvhwoym2zfg7p3dymhggkxple',
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_epbabFNGd',
    userPoolWebClientId: '6bnb5vo378cd448vfrsjq967fc',
  }
}

editingConfig.valuesMode = VALUES_PLAIN

Amplify.configure(awsConfig)
//PubSub.configure(awsConfig)

@inject('languagesStore','postStore')
@observer
export class Main extends React.Component {

  render() {
    return <Router history={history}>
      <div lang={this.props.languagesStore.selectedLanguage}>
        <ToastContainer position="bottom-left" autoClose={3000} theme="dark" />
        <NavBar origin={window.location.origin+'/post'} />

        <Route path={uiBase} exact component={Home} />
        <Route path="/author/:id"  component={Author} />
        <Route path="/block/:id"   component={Authenticated(Block)} />
        <Route path="/blog"        component={Authenticated(Blog)} />
        <Route path="/post"        component={Authenticated(Post)} />
        <Route path="/settings"    component={Authenticated(Reader)} />
        <Route path="/genre/:id"   component={Genre} />
        <Route path="/series/:id"  component={Series} />
        <Route path="/book"        component={Book} />
        <Route path="/season/:id"  component={Season} />
        <Route path="/episode/:id" component={Episode} />

      </div>
    </Router>
  }
}
