import { action, observable } from 'mobx'

class ModeState {

  @observable state = 'menu'

  switchTo = state => action(e => {
    e.preventDefault()

    this.state = state
  })

}

export const mode = new ModeState()
