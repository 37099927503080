import { action, autorun, observable, extendObservable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import api from './api'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries       from '../graphql/queries'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { requiredParam } from 'sdc-utilities'
import * as store        from 'sdc-mobx-stores'

import { editingMode, EDITING_MODE }  from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { awsDataToEntry } from 'sdc-data-models'

import { AmplifyStore   } from '../amplify/store'

import { ui            } from '../design'
import { routingStore  } from '../history'

const shortened = text => text.substring(0, text.indexOf('\n',1000)+1) + '\n\n<div style="color: #fc9; text-align: center; width: 100%; opacity: 0.5">- gekürzt -</div>'

const typeID = 'RZMZ2Yv3rpMAz25nmQE05DZDziPyhgu5'

const reorder = (list,startIndex,endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex,1)
  result.splice(endIndex,0,removed)
  return result
}

const today = () => moment().toISOString()

const notHidden = { or: [
  { hidden : { attributeExists: false} },
  { hidden : { eq: false } },
] }
const published = () => ({ or: [
  { published: { attributeExists: false} },
  { published: { eq: null } },
  { published: { le: today() } },
] })

class EpisodeStore extends AmplifyStore {

  episodeID = null

  @observable episodeByID    = {}
  @observable gepinntByID    = {}
  @observable latestByID     = {}
  @observable episodeByIndex = {}

  @observable suche    = ''
  @observable latest   = []
  @observable gepinnt  = []
  @observable byIndex  = []
  @observable byIndex4view = []
  @observable showHidden = false

  seasonID   = null

  constructor({
    seasonStore = requiredParam('seasonStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
      name : 'episode',
    })
    this.clearViewing = false
    this.seasonStore  = seasonStore

    autorun(() => {
      if (seasonStore.selected.id && this.seasonID !== seasonStore.selected.id) {
        this.seasonID = seasonStore.selected.id
        this.episodeID = null
        this.clearSelected()
        this.loadSeason()
      }
    })

    autorun(() => {
      if (userStore.user.id) {
        setTimeout(() => {
          if (this.episodeID) this.loadByID(this.episodeID);
          this.loadLatest()
        },100)
      }
    })
    autorun(() => {
      if (userStore.user.admin) {
        setTimeout(() => {
          if (this.seasonID)  this.loadSeason();
        },100)
      }
    })

    this.createToEntry = awsDataToEntry('createEpisode')
    this.updateToEntry = awsDataToEntry('updateEpisode')

    this.beforeUpdate  = this.patchBeforeUpdate

    subscribe('network-changed', speed => {
      this.loadSeason()
      this.loadLatest()
    })

    subscribe('episode-entry-selected', this.episodeSelected)
    subscribe('episode-entry-updated',  this.episodeUpdated)

    subscribe(EDITING_MODE, this.processEditingMode)

    subscribe('episode', id => {
      if (id && id !== this.episodeID) {
        console.log('episode changed from',this.episodeID,'to',id)
        this.episodeID = id
        if (!this.selected.id) {
          setTimeout(() => {
            this.loadByID(id)
          },100)
          setTimeout(() => {
            this.loadByID(id)
          },500)
          setTimeout(() => {
            this.loadByID(id)
          },1500)
        }
      }
    })
  }

  processEditingMode = mode => {
    if (mode !== 'edit') return;
    if (this.editingStore.selected.id !== this.selected.id) return;

    this.editingStore.setBuffers(this.selected)
  }

  resetNotified = episode => action(e => {
    this.updateEntryField(episode,'notified',0)
  })

  injectIndex = text => {
    let temp = text.replace(/\n/g,'\n\n')

    // temp = this.replaceASINs(temp)
    // temp = this.replaceLinks(temp)

    return temp
  }

  // replaceASINs = text => {
  //   let temp = text

  //   const regex = /\[\[\[([^\]]+)\]\]\]/g
  //   const matches = text.match(regex)
  //   if (matches) {
  //     matches.forEach(pattern => {
  //       const [asin,cover] = pattern.replace(/[\[\]]/g,'').split('/')
  //       temp = temp.replace(pattern,`<a target="_blank" rel="noopener noreferrer" href="https://www.amazon.de/dp/${asin}?tag=zettelkasten-21" class="amazon-link"><img src="https://images-eu.ssl-images-amazon.com/images/I/${cover}._SL160_.jpg" /></a>`)
  //     })
  //   }

  //   return temp
  // }

  // replaceLinks = text => {
  //   let temp = text

  //   const regex = /\[\[([^\]]+)\]\]/g
  //   const matches = text.match(regex)
  //   if (matches) {
  //     matches.forEach(pattern => {
  //       const index = pattern.replace(/[\[\]]/g,'')
  //       const target = this.episodeByIndex[index]
  //       if (target?.id) {
  //         temp = temp.replace(pattern,`[${index}](episode?${target.id})`)
  //       } else {
  //         temp = temp.replace(pattern,`<span class="broken-link">${index}</span>`)
  //       }
  //     })
  //   }

  //   return temp
  // }

  handleClick = e => {
    if (e?.target?.nodeName === 'IMG') return;

    const id = e?.target?.getAttribute('data-id')
    if (id) {
      const episode = this.episodeByID[id]
      if (episode) {
        e.preventDefault()
        this.select(episode)()
        return;
      }
    }

    const href = e?.target?.href
    if (href) {
      console.log(href)
      const ids = href.match(/\d+$/)
      console.log(ids)
      if (ids?.length) {
        const episode = this.episodeByID?.[parseInt(ids[0])]
        if (episode) {
          e.preventDefault()
          this.select(episode)()
        }
      }
    // } else {
    //   e.preventDefault()
    //   editingMode.setMode('edit')()
    }
  }

  @action
  clearData = () => {
    this.dataList   = []

    this.episodeByID = {}
    this.gepinntByID = {}

    this.suche    = ''
    this.latest   = []
    this.gepinnt  = []
    this.byIndex  = []
    this.byIndex4view = []
  }

  @action
  createEpisode = e => {

    editingMode.setMode('edit')()
    this.create({
      seasonID : this.seasonStore.selected.id,
      protected: true,
      hidden   : true,
    })()
  }

  loadPinned = () => {
    const filter = {
      and: [
        { pinned : { eq : true } },
        notHidden,
        published(),
      ]
    }
    this.listBy('listEpisodes',filter,this.parsePinned)
  }

 loadLatest = () => {
    const today = moment().toISOString()
    const filter = {
      and: [
        notHidden,
        { published: { le: today } },
      ]
    }
    this.listBy('listEpisodes',filter,this.parseLatest)
  }

  loadSeason = () => {
    const filter = userStore.user.admin ? {
      seasonID : { eq : ''+this.seasonStore.selected.id },
    } : {
      and: [
        { seasonID : { eq : ''+this.seasonStore.selected.id } },
        notHidden,
        published(),
      ]
    }
    this.listBy('listEpisodes',filter,this.parseSeason)
  }

  loadByID = id => {
    const filter = {
      id : { eq : id },
    }
    this.listBy('listEpisodes',filter,this.parseSelected)
  }

  protectContent = payload => {
    if (!userStore.user.id) {
      for (const episode of payload) {
        if (episode.protected) {
          episode.content = shortened(episode.content)
        }
      }
    }
  }

  @action
  parsePinned = payload => {
    if (payload) {
      this.protectContent(payload)
      this.gepinnt     = _.orderBy(payload, ['season.tag','index'])
      this.gepinntByID = _.keyBy(payload, 'id')
      if (this.episodeID && this.gepinntByID[this.episodeID]) {
        this.select(this.gepinntByID[this.episodeID])()
      }
      this.reducePinned()
    }
  }

  @action
  parseLatest = payload => {
    if (payload) {
      this.protectContent(payload)
      this.latest = _.orderBy(payload, ['published'],['desc']).splice(0,3)
      this.latestByID = _.keyBy(this.latest, 'id')
      this.loadPinned()
    }
  }

  @action
  reducePinned = () => {
    this.gepinnt = this.gepinnt.filter(e => !this.latestByID[e.id]).splice(0,6)
    this.gepinntByID = _.keyBy(this.gepinnt, 'id')
  }

  @action
  parseSeason = payload => {
    if (payload) {
      this.protectContent(payload)
      this.dataList = payload
      this.refreshData()
    }
  }

  @action
  parseSelected = payload => {
    if (payload) {
      this.protectContent(payload)
      if (!this.selected.id) {
        this.preselect(payload[0])()
      }
    }
  }

  navigate = offset => e => {
    const index = this.selected.index + offset
    this.preselect(this.episodeByIndex[index])()
  }

  preselect = episode => e => {
    if (episode) {
      routingStore.push('/episode/'+episode.id)
      this.select(episode)()
    }
  }

  refreshData = () => {
    this.buildIndex()
    this.updateByIndex()

    if (this.selected.id && this.selected !== this.episodeByID[this.selected.id]) {
      this.preselect(this.episodeByID[this.selected.id])()
    }
  }

  @action
  updateIndex = () => {
    this.episodeByID[this.selected.id] = this.selected
  }

  @action
  buildIndex = () => {
    this.episodeByID    = _.keyBy(this.dataList, 'id')
    this.episodeByIndex = _.keyBy(this.dataList, z => z?.index || z?.name || z.id)
  }

  @action
  updateByIndex = () => {
    this.byIndex  = _.orderBy(this.dataList, ['index','key','name'])
    this.byIndex4view = userStore.user.admin ? this.byIndex.reverse() : this.byIndex
  }

  afterCreate = entry => {
    this.setSelected(entry)
    this.typesStore.selectType(this.typeID)
    const field = this.typesStore.fields.season
    this.editingStore.setBuffers(entry)
    // this.editingStore.assign(field)({
    //   value: this.seasonStore.selected.id
    // })
    this.updateSeasonSize()
  }

  @action
  updateSeasonSize = () => {
    if (userStore.user.admin) {
      console.log('updateSeasonSize()')
      this.seasonStore.updateEntryField(this.seasonStore.selected,'size',this.dataList.filter(e =>
        !e.published || moment().isAfter(e.published)
      ).length)
      this.seasonStore.updateEntryField(this.seasonStore.selected,'wordcount',_.reduce(this.dataList.filter(e =>
        !e.published || moment().isAfter(e.published)
      ).map(e => e.wordcount || 0), (sum,n) => sum+n))
    }
  }

  episodeSelected = episode => {
    if (episode) {
      // const now = Math.floor(new Date().valueOf() / 1000)
      // if (!episode?.lastViewed || episode.lastViewed + 10 < now) {
      //   setTimeout(this.setLastViewed(now), 100)
      // }
      if (this.seasonStore.selected.id !== episode.seasonID) {
        console.log('changing season from',this.seasonStore.selected.id,'to',episode.seasonID)
        const season = this.seasonStore.seasonByID[episode.seasonID]
        if (season) {
          this.seasonStore.select(season)()
          this.select(this.selected)()
        }
      }
    }
  }

  episodeUpdated = episode => {
    if (episode?.id === this.selected.id) {
      console.log(`episodeUpdated() publishAt = ${episode?.publishAt}`)
      this.editingStore.digestUpdate(episode)
      this.refreshData()
    }
  }

  @action
  toggleVisibility = event => {
    this.updateEntryField(this.selected,'hidden',!this.selected.hidden)
  }

  @action
  toggleVisibilityFilter = event => {
    this.showHidden = !this.showHidden
  }

  @action
  patchBeforeUpdate = episode => {
    const index = this.byIndex.indexOf(this.episodeByID[episode.id]) + 1
    if (episode.key && episode.index !== index) {
      episode.index = index
    }
    if (episode.content?.length) {
      episode.wordcount = episode.content.split(/\s+/).length
    }
    if (episode.published) {
      episode.publishAt = Math.floor(moment(episode.published).toDate().valueOf() / 1000)
      episode.notified  = episode.notified || 0
      episode.hidden    = !!episode.hidden
    }
    this.updateSeasonSize()
  }

  // @action
  // onUpdateEpisode = episode => {
  //   if (episode) {
  //     this.dataList = update(this.dataList)(episode)
  //     if (this.selected.id === episode.id) {
  //       this.selected = episode
  //       if (editingMode.isViewMode.get()) {
  //         this.editingStore.setSelected(this.selected)
  //         this.editingStore.setBuffers(this.selected)
  //       }
  //     }
  //     this.loadLatest()

  //     this.updateSeasonSize()
  //   }
  // }

}

export default ({...options}) => new EpisodeStore({...options,api:api()})
