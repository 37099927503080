import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createReader, { input : toJS(entry) })),
  list   : params => () => {},
  update : delta  => () => API.graphql(graphqlOperation(mutations.updateReader, { input : toJS(delta) })),
  delete : entry  => () => {},
})
