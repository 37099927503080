import React from 'react'
import { observer } from 'mobx-react'

import { labelsStore } from 'sdc-i18n-stores'

export const PostName = observer(({post,...props}) =>
<span {...props}>
  {post?.title && <i className={'fa fa-fw fa-thumb-tack priority-'+(post?.priority || 0)}
  />} {post?.title || labelsStore.labelFor('post.unnamed')}
</span>)
