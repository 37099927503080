import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'
import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'

import { Page          } from '../layout'
import { PageHeader    } from '../layout'

import { GenreList     } from './list'
import { GenreDetails  } from './details'
import { GenreMenu     } from './right'

export const Genre = inject('genreStore')(observer(({genreStore}) => <Page left={GenreMenu}>
  <PageHeader label="genre" />
  {userStore.user.admin && <GenreList />}
  <GenreDetails />
</Page>))
