import React from 'react'
import { inject, observer } from 'mobx-react'
import { Container, Row } from 'reactstrap'

import { Page } from '../layout'

import { toEpisodeCard } from '../episode/card'

export const Home = inject('seasonStore','episodeStore')(observer(({seasonStore,episodeStore}) =>
<Page>
  <h4>Neueste Episoden</h4>
  <Container fluid><Row>
    {episodeStore.latest.map(toEpisodeCard(seasonStore,episodeStore,true))}
  </Row></Container>
  <h4>Angepinnte Episoden</h4>
  <Container fluid><Row>
    {episodeStore.gepinnt.map(toEpisodeCard(seasonStore,episodeStore))}
  </Row></Container>
</Page>))
