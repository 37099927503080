import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries       from '../graphql/queries'

import { awsDataToEntry } from 'sdc-data-models'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { update        } from 'sdc-mobx-stores'

import { AmplifyStore   } from '../amplify/store'

import { routingStore  } from '../history'

import api from './api'

const typeID = 'Pq0zJnfPH2mlVpm2Z4a4rYlCAubIVT3t'

class BlockStore extends AmplifyStore {

  blockID   = null

  @observable blockByID = {}

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'block',
    })
    this.clearViewing = false

    this.createToEntry = awsDataToEntry('createBlock')
    this.updateToEntry = awsDataToEntry('updateBlock')

    subscribe('network-changed', speed => {
      this.reload()
    })

    subscribe('block-entry-created',  this.blockCreated)
    subscribe('block-entry-updated',  this.blockUpdated)
    subscribe('block-entry-selected', this.blockSelected)

    subscribe('block', id => {
      if (id && id !== this.blockID) {
        console.log('block changed from',this.blockID,'to',id)
        this.blockID = id
        if (!this.selected.id) {
          setTimeout(() => {
            this.loadByID(id)
          },100)
          setTimeout(() => {
            this.loadByID(id)
          },500)
          setTimeout(() => {
            this.loadByID(id)
          },1500)
        }
      }
    })
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  loadByID = id => {
    const variables = {
      filter: {
        id : { eq : id },
      },
      limit: 1000
    }
    API.graphql({
      query: queries.listBlocks,
      variables,
      authMode: 'API_KEY',
    }).then(
      this.parseSelected
    ).catch(error => {
      console.error(error.errors?.[0]?.message || error)
    })
  }

  preselect = block => e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (block) {
      routingStore.push('/block/'+block.id)
      this.select(block)(e)
    }
  }

  parseAWS = deferred => action(data => {
    if (data?.listBlocks?.items) {
      const payload = data.listBlocks.items

      this.blockByID = _.keyBy(payload, 'id')
      this.dataList = _.orderBy(payload, 'tag')
    }
    deferred.resolve(this.dataList)
  })

  @action
  parseSelected = response => {
    const payload = response?.data?.listBlocks?.items
    if (payload) {
      if (!this.selected.id) {
        this.preselect(payload[0])()
      }
    }
  }

  blockSelected = block => {
    if (block) {
      this.setViewingMode()
    }
  }

  blockUpdated = block => {
    if (block) {
      this.patch(block)
    }
  }

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateBlock(response?.data?.updateBlock)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateBlock = block => {
    if (block) {
      this.dataList = update(this.dataList)(block)
      if (this.selected.id === block.id) {
        this.selected = block
      }
    }
  }

}

export default ({...options}) => new BlockStore({...options,api:api()})
