import React from 'react'
import cn from 'classnames'

import { Col, Card, CardHeader, CardBody, CardFooter } from 'reactstrap'

import { userStore } from 'sdc-auth-user'

import { EpisodeTitle } from './title'

const shortened = (text,length) => {
  if (!text?.length) return ''
  const temp = text.length < length ? text : text.substring(0, text.indexOf('.',length)+1)
  return temp.length < length ? temp : temp.substring(0, text.lastIndexOf('.',temp.length-2)+1)
}

export const toEpisodeCard = (seasonStore,episodeStore,showPublished) => (episode,index) =>
<Col md="4" key={episode.id || '?'} style={{marginBottom: '6px', paddingLeft: '4px', paddingRight: '4px', cursor: 'pointer'}}>
  <Card className={'episode '+(episode.season?.theme || 'darkCyan')} onClick={episodeStore.preselect(episode)} style={{height: '100%'}}>
    <CardHeader>
      <EpisodeTitle noPin showPublished={showPublished} showGenre={!showPublished} episode={episode} />
      {episode.protected &&  userStore.user.id && <span className="pull-right" style={{color: '#3f3', opacity: .5}}><i className="fa fa-unlock" /></span>}
      {episode.protected && !userStore.user.id && <span className="pull-right" style={{color: '#c96'}}><i className="fa fa-lock" /></span>}
    </CardHeader>
    <CardBody>
      <i className="fa fa-quote-right fa-3x pull-left" style={{opacity: 0.1}} /> <div className="card-content">{shortened(episode.content,250)}</div>
    </CardBody>
    <CardFooter style={{textAlign: 'right'}} onClick={seasonStore.preselect(episode.season)} >
      <span style={{opacity: 0.5}}> Episode {episode.index} aus </span> {episode.season?.title}
    </CardFooter>
  </Card>
</Col>
