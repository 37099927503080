import   React           from 'react'
import   cn              from 'classnames'
import { inject, observer } from 'mobx-react'

import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'
import { CreateButton, Button } from 'sdc-react-basics'

import { ui            } from '../design'
import { Page          } from '../layout'
import { ModeButton    } from '../layout/mode/button'
import { H3            } from '../components'

import { PostName      } from './name'
import { PostDetails   } from './details'

export const Post = inject('blogStore','postStore')(observer(({blogStore,postStore}) => <Page>
  {blogStore.selected.id && <H3 className="post">
    <ModeButton   className="float-left" state="menu"  />
    <ModeButton   className="float-left" state="links" />
    <ModeButton   className="float-left" state="full"  />
    <span onClick={postStore.cyclePriority} className="cycle-priority">
      <PostName post={postStore.selected} />
    </span>
    {editingMode.isEditMode.get() && <span className="toggle" onClick={editingMode.setMode('view')}><i className="fa fa-eye" /></span>}
    {editingMode.isViewMode.get() && postStore.selected.id && <span className="toggle" onClick={editingMode.setMode('edit')}><i className="fa fa-edit" /></span>}
    <Button as="span" icon="eye-slash" title="toggle.visibility" className={cn(
      postStore.selected.hidden && 'hidden',
      !postStore.selected.hidden && 'visible',
    )} onClick={postStore.toggleVisibility} />
    <CreateButton as="span" className="float-right action-create" onClick={postStore.createPost} title="post.create" />
  </H3>}
  <PostDetails />
</Page>))
