import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingType      } from 'sdc-cms-writing'

import { ThemeButtons     } from '../components/theme/buttons'


const shades = ['dark','light']
const hues   = ['Gray','Blue','Pink','Red','Orange','Green','Cyan']


export const BlogDetails = inject('blogStore')(observer(({blogStore}) => {

    if (editingType.typeID !== blogStore.typeID) return null

    const data = {
    store     : blogStore,
    dataStore : blogStore,
    update    : blogStore.update,
  }

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="blog-details" type="blog" fields={['name']} />
    <ThemeButtons store={blogStore} />
  </React.Fragment></Provider>
}))
