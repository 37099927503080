import React from 'react'
import cn    from 'classnames'

import { observer  } from 'mobx-react'

import { Button    } from 'sdc-react-basics'

import { mode      } from '.'

import './button.scss'

const icons = {
  menu  : 'indent',
  links : 'bars',
  full  : 'window-maximize',
}

export const ModeButton = observer(({className='',state,...props}) => <Button
  className={cn('menu-button',className,state === mode.state && 'active')}
  icon={icons[state]} {...props}
  as="span" title={'mode.'+state}
  onClick={mode.switchTo(state)}
/>)
